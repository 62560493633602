import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {CheckBoxComponent} from './check-box.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [CheckBoxComponent],
  exports: [CheckBoxComponent],
  schemas: [NO_ERRORS_SCHEMA]

})
export class CheckBoxModule { }

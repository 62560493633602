import { Directive, ElementRef,  SimpleChanges, AfterViewInit, Input, ViewChild, } from '@angular/core';
declare var $: any;

@Directive({
  selector: '[appTable]',
})
export class TableDirective implements AfterViewInit {


  @Input() headerColor:any="";
  @Input() textColor:any="";
  @Input() footerColor:any="";
  @Input() iconColor:any="";
  constructor(private el: ElementRef) {
    el.nativeElement.classList.add('table');
    el.nativeElement.classList.add('table-hover');
  }
  ngAfterViewInit() {
      //   $(this.el.nativeElement).DataTable({
      //     "pagingType": "full_numbers"
      // });
      const table=this.el.nativeElement;
      if(table){
      const tableHeaders=table.getElementsByTagName('thead');
      if(tableHeaders && this.headerColor){
        for(let headers of tableHeaders){
          headers.style.color=this.headerColor;
        }
      }
      const tableBody=table.getElementsByTagName('tbody');
      if(tableBody && this.textColor){
        for(let body of tableBody){
          body.style.color=this.textColor;
        }
      }
    }
}

}

import { Component, OnInit,Input,ViewEncapsulation, ChangeDetectionStrategy} from '@angular/core';


@Component({
  selector: 'header-action, mat-header-action',
  template: `<i *ngIf="!show && showToggle" (click)="showHide()" class="fa fa-angle-up fa-2x center handcursor"></i>

  <i *ngIf="show && showToggle" (click)="showHide()" class="fa fa-angle-down fa-2x center handcursor"></i>

  <i *ngIf="showIcon" class="{{userIconSource}} handcursor" title="{{iconTitle}}">
       </i>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.mat-card-header]': 'true'
  }
})
export class HeaderAction implements OnInit {
  @Input() public iconSource: string = 'mdi mdi-dots-vertical  center mdi-24px';
  userIconSource: string;
  @Input() public show: boolean = true;

  @Input() public showIcon: boolean = true;
  @Input() public showToggle: boolean = false;

  @Input() public toggleId: any;
  @Input() public iconTitle: any;


  constructor() {
  }

  ngOnInit() {
    this.setSource();
    if (!this.show) {
      this.toggleId.style.display = "none";
    }
  }

  showHide() {
    try {
      if (this.show) {
        this.show = false;
        this.toggleId.style.display = "none";
      }
      else {
        this.show = true;
        this.toggleId.style.display = "block";
      }
    } catch (error) {
      console.log(error);
    }
  }

  setSource() {
    try {
      this.userIconSource = this.iconSource;
    } catch (error) {
      console.log( error);
    }
  }
}

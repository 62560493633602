import { Component, OnInit, Input, HostListener, OnChanges, ChangeDetectorRef } from '@angular/core';
import { menubar } from './../navigation-bar/menubar';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

@Component({
    selector: 'navi-collapsible',
    templateUrl: './navigation-collapsible.component.html',
    styleUrls: ['./navigation-collapsible.component.css'],
})

export class NavigationCollapsibleComponent implements OnInit, OnChanges {

    @Input() public menuList: menubar[];
    @Input() public iconColor: String = "#f47f41";
    @Input() public direction: any;
    @Input() public textColor: String = "#f47f41";
    @Input() public separateScroll: boolean;
    @Input() public navigationBackgroundColor: string = "#ffffff";
    @Input() public isHeaderFixed: boolean;
    subchildParentID: string;
    childParentID: string;
    textView: boolean = true;
    mobileView: boolean = true;

    constructor(private router: Router, private activeRouter: ActivatedRoute, private cdr :ChangeDetectorRef) {
    }

    ngOnChanges() {
      this.cdr.detectChanges();
      this.defaultDisplay();
        // setTimeout(() => this.defaultDisplay(), 1000);
    }

    ngOnInit() {
        try {
            //  this.navigationPosition();

            if (window.screen.width < 800) {
                this.mobileView = true;
            } else {
                this.mobileView = false;
            }
        } catch (error) {
            console.log(error);
        }
    }



    showSubMenu(id) {
        try {
            if (this.childParentID == id) {
                this.childParentID = "-1";

            } else {
                this.childParentID = id;
            }
            this.subchildParentID = "-1";
            this.calculateNavigationHeight();
        } catch (error) {
            console.log(error);
        }
    }

    showSubChildMenu(id) {
        try {
            if (this.subchildParentID == id) {
                this.subchildParentID = "-1";
            }
            else {
                this.subchildParentID = id;
            }
            this.calculateNavigationHeight();
        } catch (error) {
            console.log(error);
        }
    }

    navigatorResponse(menu) {
        try {
            if (!this.navFlag) {
                this.toggle();
            }
            if (menu['children'] != undefined && menu['children'] != null && menu['children'].length > 0) {
                this.showSubMenu(menu['id']);
            }
            else {
                this.childParentID = "-2";
                this.subchildParentID = "-3";
                this.setNavigationPath(menu['target']);
            }
        } catch (error) {
            console.log(error);
        }
    }
    childNavigatorResponse(childmenu) {
        try {
            if (childmenu['children'] != undefined && childmenu['children'] != null && childmenu['children'].length > 0) {
                this.showSubChildMenu(childmenu['id'])
            }
            else {
                this.setNavigationPath(childmenu['target']);
            }
        } catch (error) {
            console.log(error);
        }
    }
    childNavigatorTop(childmenu) {
        try {
            if (childmenu['children'] != undefined && childmenu['children'] != null && childmenu['children'].length > 0) { }
            else {
                this.setNavigationPath(childmenu['target']);
            }
        } catch (error) {
            console.log(error);
        }
    }
    navigatorTop(menu) {
        try {
            if (menu['children'] != undefined && menu['children'] != null && menu['children'].length > 0) { }
            else {
                this.setNavigationPath(menu['target']);
            }
        } catch (error) {
            console.log(error);
        }
    }

    setNavigationPath(newpath: String) {
        try {

            if (this.router.isActive('/' + newpath + '1', false)) {
                let link = ['/' + newpath];
                this.router.navigate(link, { skipLocationChange: true });
            } else if (this.router.isActive('/' + newpath, false)) {
                let link = ['/' + newpath + '1'];
                this.router.navigate(link, { skipLocationChange: true });
            } else {
                let link = ['/' + newpath];
                this.router.navigate(link);
            }
            this.calculateNavigationHeight();
        } catch (error) {
            console.log(error);
        }
    }

    setColor(tColor) {
        try {
            return {
                'color': tColor,
            };
        } catch (error) {
            console.log(error);
        }
    }


    /////////////////////////////////////////////////

    closeAll() {
        try {
            $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
            $('.dropdown-submenu a.third-child').parent().children('ul').hide();

            if ($('.dropdown-submenu').parent().parent().hasClass('open')) {
                $('.dropdown-submenu').parent().parent().removeClass('open');
                $('.dropdown-submenu').parent().parent().children('a').children('span.caret').removeClass('caret-right');
                $('.dropdown-submenu').parent().parent().children('a').children('span.caret').removeClass('caret-left');
            }

            if ($('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').hasClass('caret-right'))
                $('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').removeClass('caret-right');

            if ($('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').hasClass('caret-left'))
                $('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').removeClass('caret-left');
        } catch (error) {
            console.log(error);
        }
    }


    checkChild(menu) {
        try {
            if (menu['children'] != undefined && menu['children'] != null && menu['children'].length > 0) {
                $(document).ready(function () {
                    $('.dropdown a.dropdown-toggle').hover(function (e) {
                        if (!$(this).parent().has('ul').hasClass('open')) {
                            $(this).parent().has('ul').addClass('open');
                            $(this).parent().siblings().has('ul').removeClass('open');
                        }
                        if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                            $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                        }
                    });
                });
            } else {
                $('.dropdown a.dropdown-toggle').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                $('.dropdown a.dropdown-toggle').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                    $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                    $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                    $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    checkSubChild(childmenu) {
        try {
            if (childmenu['children'] != undefined && childmenu['children'] != null && childmenu['children'].length > 0) {
                var direction = this.direction;
                $(document).ready(function () {
                    $('.dropdown-submenu a.third-child').hover(function (e) {
                        if (direction == 'top') {
                            $(this).show();
                            if (!$(this).parent().siblings().children().has('ul').is(':visible')) {
                                $(this).parent().siblings().children('ul').hide();
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                            }
                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-right');
                            }
                        }
                        $(this).next('ul').show();
                    });
                });
            } else {
                $(document).ready(function () {
                    if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                        $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                        $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                        $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }


    defaultDisplay() {
        try {
            if (this.direction != undefined) {
                if (this.direction != 'top') {
                    let i = 0;
                                        let list = document.getElementById("mySidenav").getElementsByTagName('ul');
                    for (i = 0; i < list.length; i++) {
                        list[i].style.backgroundColor = this.navigationBackgroundColor;
                    }
                    this.calculateNavigationHeight();
                } else if (this.direction == 'top') {
                    let i = 0;

                    let list = document.getElementById("myNavbar").getElementsByTagName('ul');
                    for (i = 0; i < list.length; i++) {
                        list[i].style.backgroundColor = this.navigationBackgroundColor;
                    }
                    this.calculateNavigationHeight();
                }

                if (!this.mobileView) {
                    $('.sidenav-icon').css('display', 'inline-block');
                    if (this.direction == 'left') {
                        document.getElementById("main-content").style.marginLeft = "170px";
                    }
                    else if (this.direction == 'right') {
                        document.getElementById("main-content").style.marginRight = "170px";
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    navFlag: boolean = true;
    toggle() {
        try {
            if (this.navFlag) {
                this.closeNav();
            } else {
                this.openNav();
            }
        } catch (error) {
            console.log(error);
        }
    }

    openNav() {
        try {
            this.navFlag = true;
            if (!this.mobileView) {
                if (this.direction == 'left') {
                    document.getElementById("main-content").style.marginLeft = "170px";
                }
                else if (this.direction == 'right') {
                    document.getElementById("main-content").style.marginRight = "170px";
                }
                $('.sidenav-text').css('display', 'inline-block');
            }
            else {
                document.getElementById("mySidenav").style.display = "block";
            }
            this.calculateNavigationHeight();
        } catch (error) {
            console.log(error);
        }
    }

    closeNav() {
        try {
            this.navFlag = false;
            this.childParentID = "-2";
            this.subchildParentID = "-3";
            if (!this.mobileView) {
                if (this.direction == 'left') {
                    document.getElementById("main-content").style.marginLeft = "20px";
                } else {
                    document.getElementById("main-content").style.marginRight = "20px";
                }
                $('.sidenav-text').css('display', 'none');
            } else {
                document.getElementById("mySidenav").style.display = "none";
            }
            this.calculateNavigationHeight();
        } catch (error) {
            console.log(error);
        }
    }

    calculateNavigationHeight() {
        try {
            setTimeout(() => {
                var footerClass = document.getElementsByClassName("footer");
                var headerClass = document.getElementsByClassName("header");
                var bottom = 0;
                var upper = 0;
                if (footerClass.length > 0) {
                    let elm = footerClass[0];
                    if (elm) {
                        bottom = elm['clientHeight'];
                    }
                }
                if (headerClass.length > 0) {
                    let elm2 = headerClass[0];
                    if (elm2) {
                        upper = elm2['clientHeight'];
                    }
                }
                $(document).ready(() => {
                    document.getElementsByClassName("footer")[0]['style']['bottom'] = '0px';

                    var screenshown2 = $(window).innerHeight();
                    var temp = $(document).outerHeight();
                    var body1 = document.getElementById('bodyy').scrollHeight;

                    if (this.direction != 'top') {
                        var heightSideNav = document.getElementById("mySidenav").scrollHeight;
                        if (screenshown2 == temp) {
                            var a = 0;
                            if ((heightSideNav) > (body1 - 20)) {
                                a = (screenshown2 - upper - bottom - 30) - heightSideNav;
                            } else {
                                a = (screenshown2 - upper - bottom) - body1;
                            }
                            if (a < 0) {
                                document.getElementsByClassName("footer")[0]['style']['bottom'] = a + 'px';
                            }
                        }
                        else if (screenshown2 < temp) {
                            var b = 0;
                            if ((screenshown2 - upper - 20 - heightSideNav) > 0 && (screenshown2 - upper - body1) > 0) {
                                if (heightSideNav < (body1 - 30)) {
                                    if ((temp - upper - body1) < bottom) {
                                        b = temp - upper - body1 - bottom;
                                    }
                                    else {
                                        b = -10;
                                    }
                                } else {
                                    if ((temp - upper - 20 - heightSideNav) < bottom) {
                                        b = (temp - upper - 30 - heightSideNav) - bottom;
                                    }
                                }
                            } else {
                                if (heightSideNav > (body1 - 30)) {
                                    if (screenshown2 - upper - body1 < 0) {
                                        if ((temp - upper - body1) >= bottom) {
                                            b = ((body1 - 20) - heightSideNav) - (bottom - 50);
                                        } else if ((temp - upper - body1) < bottom) {
                                            let x = 0;
                                            if (bottom > 50) {
                                                x = -bottom + 50;
                                            }
                                            let s = heightSideNav + 20 - body1;
                                            b = x - s;
                                            if (this.isHeaderFixed) {
                                                b = b - 50;
                                            }
                                        }
                                    } else {
                                        b = screenshown2 - temp - bottom;
                                    }
                                } else {
                                    if ((temp - upper - body1 - 10) < bottom) {
                                        b = (temp - upper - body1 - 10 - bottom);
                                    }
                                }
                            }
                            if (b < 0) {
                                document.getElementsByClassName("footer")[0]['style']['bottom'] = b + 'px';
                            }
                        }
                    }
                    else if (this.direction == 'top') {
                        var aa = 0;
                        aa = temp - upper - body1 - bottom;
                        if (aa < 0) {
                            document.getElementsByClassName("footer")[0]['style']['bottom'] = aa + 'px';
                        }
                    }
                });
              }, 20);
            // }, 200);
        } catch (error) {
            console.log(error);
        }
    }

    @HostListener('document:click', ['$event'])
    onClick(event) {
        this.calculateNavigationHeight();
    }

    // navigationPosition() {
    //     try {
    //         if (this.separateScroll) {
    //             $(window).ready(function () {
    //                 $(".sidenav").addClass("fixedNav");
    //             });
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
}

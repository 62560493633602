import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { TranslateService } from 'ng2-translate/ng2-translate';
import { Theme } from '../../service/guiTheme';
import { GUIService } from '../../service';
@Component({
  selector: 'c-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  constructor(private guiService: GUIService, private translate: TranslateService) {
  }
  @ViewChild('header2') border: any;
  @Input('gui-theme') theme: Theme = new Theme();
  tempTheme: Theme = new Theme();

  allLanguageList: any = [];

  ngOnChanges() {
    if (this.theme === undefined) {
      this.theme = this.tempTheme;
    }
  }
  ngOnInit() {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  ngAfterViewInit() {
    try {
      setTimeout(() => {
        if (this.theme && this.theme.applyHeaderBorder === 'yes') {
          this.setHeaderBorder();
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }

  setColor(tColor) {
    try {
      return this.guiService.setColor(tColor);
    } catch (error) {
      console.log(error);
    }
  }

  setTheme(tColor, sColor?) {
    try {
      return this.guiService.setTheme(tColor, sColor);
    } catch (error) {
      console.log(error);
    }
  }


  setHeaderBorder() {
    try {
      if (this.border && this.border.nativeElement && this.border.nativeElement.style) {
        this.border.nativeElement.style.borderBottom = '1px solid ' + this.theme.borderColor;
      }
    } catch (error) {
      console.log(error);
    }
  }
  ngOnDestroy(): void {
    try {

    } catch (error) {
      console.log(error);
    }
  }
}

import { Component, forwardRef, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
const noop = () => {
};

@Component({
  selector: 'check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxComponent),
      multi: true
    }
  ]
})
export class CheckBoxComponent implements OnInit, ControlValueAccessor {

  @Input('label')
  label: any;

  @Input('color')
  checkBoxColor: any;

  @Output('onClick')
  update = new EventEmitter<any>();

  @Input('value')
  _value: any;

  @Input('check-box-type')
  type: any = "default";

  @Input('isChecked')
  checked: boolean = false;

  @Input('isDisable')
  disabled: boolean = false;

  id: any

  constructor() { }

  ngOnInit() {
    try {
      this.setCheckBox();
    } catch (error) {
      console.log(error);
    }
  }

  onClick(event) {
    try {
      let selectedEntry: any;
      selectedEntry = Object.assign({}, selectedEntry, { label: this.label, value: this.value, checked: event.target.checked });
      this.update.emit({
        value: selectedEntry
      });
    } catch (error) {
      console.log(error);
    }
  }

  setCheckBox() {
    try {
      this.setCheckBoxColor();
      this.generateId();
      this.checkBox();
      this.setNewCheckBox();
      // if (this.type === "classic-sign") {
      //   this.setClassicSquareCheckBoxWithSign();
      // } else if (this.type === "classic") {
      //   this.setClassicSquareCheckBox();
      // } else {
      //   this.setDefaultSquareCheckBox();
      // }

    } catch (error) {
      console.log("setCheckBox() , : " + error);
    }
  }

  generateId() {
    try {
      let randomNumber = Math.floor(Math.random() * 100) + 1;
      let value1 = (this.value === undefined) ? "" : this.value;
      let value2 = (this.label === undefined) ? "" : this.label;
      this.id = value1 + value2 + randomNumber + "";
    } catch (error) {
      console.log(error);
    }
  }

  checkBox() {
    try {
      let cssClass1 = ".checkbox-custom{opacity: 0;position: absolute;}";
      let cssClass2 = ".checkbox-custom, .checkbox-custom-label{display: inline-block;vertical-align: middle;margin: 5px;cursor: pointer;}";
      let cssClass3 = ".checkbox-custom-label{position: relative;}";
      let style = document.createElement('style');
      style.appendChild(document.createTextNode(cssClass1));
      style.appendChild(document.createTextNode(cssClass2));
      style.appendChild(document.createTextNode(cssClass3));
      document.getElementsByTagName('head')[0].appendChild(style);
    } catch (error) {
      console.log(error);
    }
  }



  setCheckBoxColor() {
    try {
      if (this.checkBoxColor === undefined) {
        this.checkBoxColor = "rebeccapurple";
      }
    } catch (error) {
      console.log(error);
    }
  }


  setDefaultSquareCheckBox() {
    try {
      let cssClass1 = ".checkbox-custom:checked + .checkbox-custom-label:before {background: " + this.checkBoxColor + ";}";
      let cssClass5 = ".checkbox-custom + .checkbox-custom-label:before{content: '';background: #fff;border: 2px solid #ddd;display: inline-block;vertical-align: middle;width: 10px;height: 10px;padding: 2px;margin-right: 8px;}";
      let style = document.createElement('style');
      style.appendChild(document.createTextNode(cssClass1));
      style.appendChild(document.createTextNode(cssClass5));
      document.getElementsByTagName('head')[0].appendChild(style);
    } catch (error) {
      console.log(error);
    }
  }

  setClassicSquareCheckBoxWithSign() {
    try {
      let cssClass4 = ".checkbox-custom + .checkbox-custom-label:before {content: '';background: #fff;border: 2px solid #ddd;display: inline-block;vertical-align: middle;width: 15px;height: 15px;padding: 2px;margin-right: 5px;text-align: center;}";
      let cssClass5 = ".checkbox-custom:checked + .checkbox-custom-label:before {background: " + this.checkBoxColor + ";content: '\\f00c';font-family: 'FontAwesome';color: #fff;}";
      let style = document.createElement('style');
      style.appendChild(document.createTextNode(cssClass4));
      style.appendChild(document.createTextNode(cssClass5));
      document.getElementsByTagName('head')[0].appendChild(style);
    } catch (error) {
      console.log(error);
    }
  }

  setClassicSquareCheckBox() {
    try {
      let cssClass2 = ".checkbox-custom:checked + .checkbox-custom-label:before {background: " + this.checkBoxColor + ";box-shadow: inset 0px 0px 0px 4px #fff;}";
      let cssClass3 = ".checkbox-custom + .checkbox-custom-label:before{content: '';background: #fff;border: 2px solid #ddd;display: inline-block;vertical-align: middle;width: 15px;height: 15px;padding: 2px;margin-right: 10px;text-align: center;}";
      let style = document.createElement('style');
      style.appendChild(document.createTextNode(cssClass2));
      style.appendChild(document.createTextNode(cssClass3));
      document.getElementsByTagName('head')[0].appendChild(style);
    } catch (error) {
      console.log(error);
    }
  }

  setNewCheckBox() {
    let css1 = ".pure-checkbox input[type='checkbox']:focus+label:before,.pure-checkbox input[type='checkbox']:hover+label:before {    border-color: " + this.checkBoxColor + ";background-color: #f2f2f2;}";
    let css2 = ".pure-checkbox input[type='checkbox']+label:before {box-sizing: content-box;content: '';color: " + this.checkBoxColor + ";position: absolute;top: 50%;left: 0;width: 14px;height: 14px;margin-top: -9px;border: 2px solid " + this.checkBoxColor + ";text-align: center;transition: all 0.4s ease;}";
    let css3 = ".pure-checkbox input[type='checkbox']+label:after {box-sizing: content-box;content: '';background-color: " + this.checkBoxColor + ";position: absolute;top: 50%;left: 4px; width: 10px;height: 10px;margin-top: -5px;transform: scale(0);transform-origin: 50%;transition: transform 2ms ease-out;}"
    let css4 = ".pure-checkbox input[type='checkbox']:checked+label:before {animation: borderscale 2ms ease-in;background: " + this.checkBoxColor + ";}"
    let css5 = "@keyframes borderscale {50% {box-shadow: 0 0 0 2px " + this.checkBoxColor + ";}}";
    let style = document.createElement('style');
    style.appendChild(document.createTextNode(css1));
    style.appendChild(document.createTextNode(css2));
    style.appendChild(document.createTextNode(css3));
    style.appendChild(document.createTextNode(css4));
    style.appendChild(document.createTextNode(css5));
    document.getElementsByTagName('head')[0].appendChild(style);
  }
  private innerValue: any = '';
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }


  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}


import { Directive, ElementRef, HostListener, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[onlyNumber]',
})
export class OnlyNumber {
  constructor(el: ElementRef) {
    // el.nativeElement.style.textAlign = "left";
  }

  @Input() allowPlus: string;
  @Input() min: string;
  @Input() max: string;
  @HostListener('focus', ['$event']) onFocus(event) { this.numberOnly(event); }
  @HostListener('keyup', ['$event']) onKeyup(event) { this.numberOnly(event); }
  @HostListener('keydown', ['$event']) onKeyDown(event) { this.numberOnly(event); }


  numberOnly(event) {
    let e = <KeyboardEvent>event;
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode == 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode == 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      // (e.keyCode == 107) ||
      // Allow: + sign
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    if (e.keyCode == 107) {
      if (this.allowPlus && this.allowPlus === "yes") {
        return;
      } else if (this.allowPlus && this.allowPlus === "no") {
        e.preventDefault();
      } else {
        return;
      }
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }

}


export const userIdMinLengthKey = "useridminlength";
export const userIdMaxLengthKey = "useridmaxlength";
export const passwordMinLengthKey = "passwordminlength";
export const passwordMaxLengthKey = "passwordmaxlength";
export const mobileNoMinLengthKey = "mobilenominlength";
export const mobileNoMaxLengthKey = "mobilenomaxlength";
export const firstNameMaxLengthKey = "firstNameMaxLength";
export const firstNameMinLengthKey = "firstNameMinLength";
export const lastNameMaxLengthKey = "lastNameMaxLength";
export const lastNameMinLengthKey = "lastNameMinLength";
export const hintMinLengthKey = "hintMinLength";
export const hintMaxLengthKey = "hintMaxLength";
export const twitteridMaxLengthKey = "twitteridMaxLength";
export const twitteridMinLengthKey = "twitteridMinLength";
export const facebookidMaxLengthKey = "facebookidMaxLength";
export const facebookidMinLengthKey = "facebookidMinLength";
export const skypeidMaxLengthKey = "skypeidMaxLength";
export const skypeidMinLengthKey = "skypeidMinLength";
export const linkedinidMaxLengthKey = "linkedinidMaxLength";
export const linkedinidMinLengthKey = "linkedinidMinLength";
export const serviceAliasMaxLength = 20;
export const serviceAliasMinLength = 3;
export const serviceNameMaxLength = 20;
export const serviceNameMinLength = 3;
export const serviceDisplayMaxLength = 20;
export const serviceDisplayMinLength = 3;
export const dateFormat = "dateFormat";
export const KEY_SYSTEM_TYPE = "system_type";
export const VALUE_SYSTEM_TYPE = "aggregator";
export const KEY_DEFAULT_DOMAIN = "default_domain";
export const fileMaxSizeKey = "filemaxsize";
export const KEY_RESELLER_DEFAULT_DOMAIN = "reseller_default_domain";
export const DEFAULT_SYSTEM_CURRENCY_SYMBOL = '₹';
export const DEFAULT_SYSTEM_CURRENCY_CODE = 'INR';
export const KEY_SYSTEM_CURRENCY_CODE = 'system_currency';
export const KEY_SYSTEM_CURRENCY_SYMBOL = 'system_currency_symbol';
export const KEY_ALERT_FILE_UPLOAD_SIZE = 'alert_file_upload_size';
export const ALLOW_SUBUSER_APIKEY = 'allow_subuser_apikey';
export const OLD_PASSWORD_REMEMBER_COUNT = 'passwordPolicyRemember';
export const GROWL_DISPLAY_TIME = 'gui_growl_msg_display_time';
export const DASHBOARD_NOTIFICATION = "awaiting_message";
export const THIRDPARTY_SEE_PRICING_LINK = "thirdparty_see_pricing_link";
export const THIRDPARTY_SEE_PRICING_LINK_URL = "thirdparty_see_pricing_link_url";
export const PASSWORD_STRENGTH_MESSAGE = "password_strength_message";
export const ALLOW_CAPTCHA_SERVICE="allow_captcha";
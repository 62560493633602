import { TableDirective } from './../directive/table.directive';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextComponent } from './text/text.component';
import { OnlyNumber } from '../directive/onlynumber.directive';


@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [ TextComponent, OnlyNumber,TableDirective],
  exports: [ TextComponent, OnlyNumber,TableDirective],
  schemas: [NO_ERRORS_SCHEMA]

})
export class InputModule { }

import { NgModule, Component, Input, Output, EventEmitter, forwardRef, ChangeDetectorRef, OnInit, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const PROGRESS_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProgressBar),
    multi: true
};

@Component({
    selector: 'app-progress-bar',
    template: `
        <div class="windows-loader" *ngIf="isWindowsLoader"></div>
    <div class="loading" *ngIf="isBouncingBall">
        <div class="ball"></div>
        <span class="loading-text" [attr.data-text]="value">{{value}}</span>
    </div>
    <div class="load-bar">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
    </div>
    <div class="circle-with-inner-ball" *ngIf="isCircleWithInnerBall"></div>
    <div class="floating-point" *ngIf="isFloting">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    `,
    styleUrls: ['./progress-bar.component.css'],
    providers: [PROGRESS_VALUE_ACCESSOR]
})
export class ProgressBar implements ControlValueAccessor, OnInit, OnChanges {


    model: any;
    @Input('progress-type')
    type: any;

    @Input('progress')
    progress: any;

    @Input('loading-text')
    value: any = 'It\'s loading...';

    @Input('ball-color')
    ballColor: any;

    @Input('ball-text-color')
    ballTextColor: any;

    @Input('colors')
    colors: any = [];

    isFloting = false;
    isCircleWithInnerBall = false;
    isBouncingBall = false;
    isWindowsLoader = false;

    onModelChange: Function = () => { };

    onModelTouched: Function = () => { };


    constructor(private cd: ChangeDetectorRef) { }



    ngOnInit() {
        this.setProgress();
    }

    ngOnChanges(){
      this.cd.detectChanges();
      this.setProgress();
    }

    disableAll() {
        this.isFloting = false;
        this.isWindowsLoader = false;
        this.isBouncingBall = false;
        this.isCircleWithInnerBall = false;
    }


    setProgress() {
        try {
            this.disableAll();
            if (this.progress !== undefined) {
                if (this.progress === 'floating-points') {
                    this.setValuesForFloatingLoader();
                } else if (this.progress === 'circle') {
                    this.setCircleWithInnerball();
                } else if (this.progress === 'bouncing-ball') {
                    this.setBouncingBallWithText();
                } else if (this.progress === 'square-loader') {
                    this.getWindowsLoader();
                } else if (this.progress === 'bar') {
                    this.getBar();
                } else {
                    this.setValuesForFloatingLoader();
                }
            } else {
                this.setValuesForFloatingLoader();
            }
        } catch (error) {
            console.log('setProgress(),: ' + error);
        }
    }



    getWindowsLoader() {
        try {
            this.isWindowsLoader = true;
            const cssClass1 = '.windows-loader {width: 60px;height: 60px;margin: 60px;animation: rotate 1.4s infinite ease-in-out, background 1.4s infinite ease-in-out alternate;}';
            const cssClass2 = '@keyframes rotate {0% {transform: perspective(120px) rotateX(0deg) rotateY(0deg);}50% {transform: perspective(120px) rotateX(-180deg) rotateY(0deg);}100% {transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);}}';
            const cssClass3 = '@keyframes background {0% {background-color: #FF00CC;}50% {background-color: #0033FF;}100% {background-color: #FF66FF;}}';
            const style = document.createElement('style');
            style.appendChild(document.createTextNode(cssClass1));
            style.appendChild(document.createTextNode(cssClass2));
            style.appendChild(document.createTextNode(cssClass3));
            document.getElementsByTagName('head')[0].appendChild(style);
        } catch (error) {
            console.log('getWindowsLoader(), : ' + error);
        }
    }

    setValuesForFloatingLoader() {
        try {
            this.isFloting = true;
            let ballColor1 = '#0033FF'; // blue
            let ballColor2 = '#FF33CC'; // pink
            let ballColor3 = '#33FF00'; // green
            let ballColor4 = '#FFFF00'; // orange yellow
            if (this.colors !== undefined) {
                ballColor1 = (typeof this.colors[0] === 'undefined') ? '#0033FF' : this.colors[0];
                ballColor2 = (typeof this.colors[1] === 'undefined') ? '#FF33CC' : this.colors[1];
                ballColor3 = (typeof this.colors[2] === 'undefined') ? '#33FF00' : this.colors[2];
                ballColor4 = (typeof this.colors[3] === 'undefined') ? '#FFFF00' : this.colors[3];
            }
            this.getFloatingPointLoader(ballColor1, ballColor2, ballColor3, ballColor4);
        } catch (error) {
            console.log('setValuesForFloatingLoader() ,:' + error);
        }
    }

    getFloatingPointLoader(ballColor1: any, ballColor2: any, ballColor3: any, ballColor4: any) {
        try {
            const cssClass1 = '.floating-point div {width: 20px;height: 20px;position: absolute;left: -20px;top: 40px;background-color: ' + ballColor1 + ';border-radius: 50%;animation: move 4s infinite cubic-bezier(.2,.64,.81,.23);}';
            const cssClass2 = '.floating-point div:nth-child(2) {animation-delay: 150ms; background-color: ' + ballColor2 + ';}';
            const cssClass3 = '.floating-point div:nth-child(3) {animation-delay: 300ms; background-color: ' + ballColor3 + ';}';
            const cssClass4 = '.floating-point div:nth-child(4) {animation-delay: 450ms; background-color: ' + ballColor4 + ';}';
            const cssClass5 = '@keyframes move {0% {left: 0%;}75% {left:100%;}100% {left:100%;}}';
            const style = document.createElement('style');
            style.appendChild(document.createTextNode(cssClass1));
            style.appendChild(document.createTextNode(cssClass2));
            style.appendChild(document.createTextNode(cssClass3));
            style.appendChild(document.createTextNode(cssClass4));
            style.appendChild(document.createTextNode(cssClass5));
            document.getElementsByTagName('head')[0].appendChild(style);
        } catch (error) {
            console.log('getFloatingPointLoader(), : ' + error);
        }
    }

    setCircleWithInnerball() {
        try {
            this.isCircleWithInnerBall = true;
            let ballColor: '#FF66FF';
            ballColor = (typeof this.ballColor === 'undefined') ? '#FF66FF' : this.ballColor;
            this.getCircleWithInnerball(ballColor);
        } catch (error) {
            console.log('setCircleWithInnerball() ,: ' + error);
        }
    }

    getCircleWithInnerball(ballColor: any) {
        try {
          const cssClass1 = '.circle-with-inner-ball {box-sizing: border-box;height: 60px;width: 60px;margin: 80px;border: 0px solid #27ae60;border-radius: 50%;box-shadow: 0 -20px 0 24px ' + ballColor + ' inset;animation: circle-inner-rotate 1s infinite linear;}';
            const cssClass2 = '@keyframes circle-inner-rotate {0% {transform: rotate(0deg);}100% {transform: rotate(360deg);}}';
            const style = document.createElement('style');
            style.appendChild(document.createTextNode(cssClass1));
            style.appendChild(document.createTextNode(cssClass2));
            document.getElementsByTagName('head')[0].appendChild(style);
        } catch (error) {
            console.log('getCircleWithInnerball(), : ' + error);
        }
    }

    getBar() {
        try {
          const cssClass4 = '.load-bar {position: relative;margin-top: 20px;width: 100%;height: 6px;background-color: #fdba2c;}';
          const cssClass5 = '.bar {content: "";display: inline;position: absolute;width: 0;height: 100%;left: 50%;text-align: center;}';
          const cssClass6 = '.bar:nth-child(1) {background-color: #da4733;animation: bar-loading 3s linear infinite;}';
          const cssClass1 = '.bar:nth-child(2) {background-color: #3b78e7;animation: bar-loading 3s linear 1s infinite;}';
          const cssClass2 = '.bar:nth-child(3) {background-color: #fdba2c;animation: bar-loading 3s linear 2s infinite;}';
          const cssClass3 = '@keyframes bar-loading {from {left: 50%; width: 0;z-index:1000;}33.3333% {left: 0; width: 100%;z-index: 10;}to {left: 0; width: 100%;}}';
          const style = document.createElement('style');
            style.appendChild(document.createTextNode(cssClass1));
            style.appendChild(document.createTextNode(cssClass2));
            style.appendChild(document.createTextNode(cssClass3));
            style.appendChild(document.createTextNode(cssClass4));
            style.appendChild(document.createTextNode(cssClass5));
            style.appendChild(document.createTextNode(cssClass6));
            document.getElementsByTagName('head')[0].appendChild(style);
        } catch (error) {
            console.log('getBar(), : ' + error);
        }
    }

    setBouncingBallWithText() {
        try {
            this.isBouncingBall = true;
            let ballColor = '#FF66FF';
            let textColor = '#0000FF';
            ballColor = (typeof this.ballColor === 'undefined') ? '#FF66FF' : this.ballColor;
            textColor = (typeof this.ballTextColor === 'undefined') ? '#0000FF' : this.ballTextColor;
            this.getBouncingBallWithText(ballColor, textColor);
        } catch (error) {
            console.log('setBouncingBallWithText(), :' + error);
        }
    }



    getBouncingBallWithText(ballColor: any, textColor: any) {
        try {
          const cssClass4 = '.loading-text {position: relative;color: rgba(0, 0, 0, .3);font-size: 1em;display: inherit;}';
          const cssClass5 = '.loading-text:before {content: attr(data-text);position: absolute;overflow: hidden;max-width: 100%;white-space: nowrap;color: ' + textColor + ';animation: test 4s linear infinite;}';
          const cssClass6 = '@keyframes test {0% {max-width: 0;}}';
          const cssClass1 = '.loading {margin-top: 50px;margin-left: 50px;font-size: 20px;font-family: sans-serif;}';
          const cssClass2 = '.ball {display: inline-block;height: 50px;width: 50px;background-color: ' + ballColor + ';border-radius: 50%;box-shadow:inset 6px -10px 50px -20px #000;animation: loading 1s infinite;}';
          const cssClass3 = '@keyframes loading {0%, 100% {transform: translateY(0px);animation-timing-function: ease-out;}50% {transform: translateY(-30px);animation-timing-function: ease-in;}}';
          const style = document.createElement('style');
            style.appendChild(document.createTextNode(cssClass1));
            style.appendChild(document.createTextNode(cssClass2));
            style.appendChild(document.createTextNode(cssClass3));
            style.appendChild(document.createTextNode(cssClass4));
            style.appendChild(document.createTextNode(cssClass5));
            style.appendChild(document.createTextNode(cssClass6));
            document.getElementsByTagName('head')[0].appendChild(style);
        } catch (error) {
            console.log('getBouncingBallWithText() ,: ' + error);
        }

    }

    writeValue(model: any): void {
        this.model = model;
        this.cd.markForCheck();
    }

    registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {

    }
}

@NgModule({
    imports: [CommonModule],
    exports: [ProgressBar],
    declarations: [ProgressBar]
})
export class ProgressBarModule { }

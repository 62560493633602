import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const allRoutes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: '',
        loadChildren: 'src/app/outer/outer.module#OuterModule'
    },
    {
        path: '',
        loadChildren: 'src/app/home/home.module#HomeModule'
    }
];

const appRoutes: Routes = [
    ...allRoutes
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, { useHash: true });



// export const SMS = "sms";
export const GROUPS_LIST = 'groups';
export const SEND_SMS = 'sendsms';
export const ADD_GROUP = 'add';
export const EDIT_GROUP = 'edit';
export const ADD_SUB_GROUP = 'add/subgroup';
export const API_KEY_LIST = 'apikey/list';
export const CONTACTS_LIST = 'contacts';
export const ADD_CONTACT = 'add';
export const EDIT_CONTACT = 'edit';
export const SEND_BULK_SMS = 'send/bulksms';
export const SETTINGS = 'settings';
export const TEMPLATE_LIST = 'template';
export const ADD_TEMPLATE = 'add';
export const EDIT_TEMPLATE = 'edit';
export const SEND_IMPORT_SMS = 'send/importsms';
export const SENDER_ID_LIST = 'senderid';
export const DASHBOARD = 'dashboard';
export const REPORTS = 'reports';
export const SUMMARY_LOG_REPORTS = 'summary-log';
export const MESSAGE_LOG_REPORTS = 'message-log';
export const IMPORT_CONTACT = 'import';
export const PURGE_SMS = 'purge-sms';
export const PAUSE_RESUME = 'pause-resume';
export const SEND_GROUP_SMS = 'send/groupsms';
export const KEYWORD_DETAILS_REPORTS = 'keyword-detail';
export const KEYWORD_SUMMARY_REPORTS = 'keyword-summary';
export const ADD_WHITE_BLACK_LIST = 'add';
export const IMPORT_WHITE_BLACK_LIST = 'import';
export const WHITE_BLACK_LIST = 'white-black-list';
export const ADD_API_KEY = 'apikey/add';
export const CRM_UPDATE_CONTACT_STATUS = 'crm/updatestatus';
export const CRM_SETTINGS = 'crm/settings';
export const INBOX_KEYWORD_VIEW = 'keyword';
export const INBOX_KEYWORD_EDIT = 'edit';
export const INBOX_KEYWORD_SEPARATOR = 'keyword/separator';
export const INBOX_KEYWORD_INTEGRATION_VIEW = 'keyword/integration';
export const INBOX_KEYWORD_INTEGRATION_ADD = 'add';
export const INBOX_KEYWORD_INTEGRATION_EDIT = 'edit';
export const SEND_EMAIL = 'send/email';
export const SEND_EMAIL_LIST = 'send/email/list';
export const CRM_STATUS_REPORT = 'crm/status';
export const SEND_EXCEL_CUSTOM_SMS = 'send/customimportsms';
export const SMS_USER = 'user';
export const ADD_SMS_USER = 'add';
export const EDIT_SMS_USER = 'edit';
export const EXCEL_PLUG = 'send/excelplugin';
export const SCHEDULE_SMS_REPORT = 'schedule/sms';
export const OVERRIDE_MESSAGE_SETTINGS = 'override-message-settings';
export const DUPLICATE_SMS_CONFIGURATION = 'duplicate-sms-configuration';
export const DLT_TEMPLATE = 'dlt-template';
export const ADD_DLT_TEMPLATE = 'add';
export const EDIT_DLT_TEMPLATE = 'edit';
export const LIST_DLT_TEMPLATE = 'list';
import { Injectable } from '@angular/core';

import { apiPath } from '../config';
import { SMS_PARAMETERS_LOOKUP } from './message-api-constants';
import { ApiService } from '../../app/shared/service';
import {
  MOBILENO_MAX_LENGTH_KEY, MOBILENO_MIN_LENGTH_KEY, SENDER_ID_MAX_LENGTH_KEY
  , SENDER_ID_MIN_LENGTH_KEY, ALLOW_OPEN_SENDERID, MAX_DAYS_REPORT_LIMIT, ALLOW_CUSTOM_OPTOUT, CUSTOM_OPTOUT_CONTENT, DLT_TEMPLATE_VALIDATION, DLT_ENTITY_ID_MAX_LENGTH, DLT_ENTITY_ID_MIN_LENGTH, DLT_MODULE
} from './sms-parameters-key';
import { SMSParameterCriteria } from './smsparameter-criteria';


@Injectable()
export class SmsParametersService {

  constructor(public apiService: ApiService) {
  }

  private _dltIdMaxLength = 20;
  private _dltMinLength = 19;
  private _senderIdMaxLength = 10;
  private _senderIdMinLength = 3;
  private _mobileNoMinLength = 10;
  private _mobileNoMaxLength = 14;
  private _allowOpenSenderid = false;
  private _maxReportDaysLimit: number = 31;
  private _allowCustomOptout = false;
  private _customOptoutContent = '';
  private _dltTemplateValidation: any;
  private _dlt_module = 'off';

  //keys for request
  private keys = [
    DLT_MODULE,
    SENDER_ID_MAX_LENGTH_KEY,
    SENDER_ID_MIN_LENGTH_KEY,
    MOBILENO_MAX_LENGTH_KEY,
    MOBILENO_MIN_LENGTH_KEY,
    ALLOW_OPEN_SENDERID,
    MAX_DAYS_REPORT_LIMIT,
    ALLOW_CUSTOM_OPTOUT,
    CUSTOM_OPTOUT_CONTENT,
    DLT_TEMPLATE_VALIDATION,
    DLT_ENTITY_ID_MAX_LENGTH,
    DLT_ENTITY_ID_MIN_LENGTH
  ]

  setParameters() {
    try {

      let body: SMSParameterCriteria = new SMSParameterCriteria(this.keys, "criteria");
      let apiUrl = apiPath + SMS_PARAMETERS_LOOKUP;
      this.apiService.post(apiUrl, body).subscribe(data => {
        let status = data['status'];
        if (status) {
          if (status['error-code'] && status['error-code'] == "000") {
            let parameterList = data['parameters'];
            if (parameterList) {
              parameterList.forEach(element => {
                this.setKey(element['key'], element['value']);
              });
            }
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
    return true;
  }


  setKey(key: any, value: any) {
    try {
      if (key && value) {
        switch (key) {
          case DLT_MODULE: {
            this._dlt_module = value;
            break;
          }
          case SENDER_ID_MIN_LENGTH_KEY: {
            this.senderIdMinLength = value;
            break;
          }
          case SENDER_ID_MAX_LENGTH_KEY: {
            this.senderIdMaxLength = value;
            break;
          }
          case MOBILENO_MAX_LENGTH_KEY: {
            this.mobilNoMaxLength = value;
            break;
          }
          case MOBILENO_MIN_LENGTH_KEY: {
            this.mobilNoMinLength = value;
            break;
          }
          case ALLOW_OPEN_SENDERID: {
            this.allowOpenSenderid = value && value == 1 ? true : false;
            break;
          }
          case MAX_DAYS_REPORT_LIMIT: {
            this.maxReportDaysLimit = value;
            break;
          }
          case ALLOW_CUSTOM_OPTOUT: {
            this.allowCustomOptout = value && (value == 'on' || value == 'ON') ? true : false;
            break;
          }
          case CUSTOM_OPTOUT_CONTENT: {
            this.customOptoutContent = value;
            break;
          }
          case DLT_TEMPLATE_VALIDATION: {
            this._dltTemplateValidation = value;
            break;
          }
          case DLT_ENTITY_ID_MIN_LENGTH: {
            this._dltMinLength = value;
            break;
          }
          case DLT_ENTITY_ID_MAX_LENGTH: {
            this._dltIdMaxLength = value;
            break;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  get dltModule() {
    if (this._dlt_module == 'on')
      return true;
    return false;
  }

  get dltEntityMaxLength() {
    return this._dltIdMaxLength;
  }

  set dltEntityMaxLength(value) {
    this._dltIdMaxLength = value;
  }

  get dltEntityMinLength() {
    return this._dltMinLength;
  }

  set dltEntityMinLength(value) {
    this._dltMinLength = value
  }

  get senderIdMaxLength() {
    return this._senderIdMaxLength;
  }

  set senderIdMaxLength(value) {
    this._senderIdMaxLength = value;
  }

  get senderIdMinLength() {
    return this._senderIdMinLength;
  }

  set senderIdMinLength(value) {
    this._senderIdMinLength = value;
  }

  get mobilNoMinLength() {
    return this._mobileNoMinLength;
  }

  set mobilNoMinLength(value) {
    this._mobileNoMinLength = value;
  }

  get mobilNoMaxLength() {
    return this._mobileNoMaxLength;
  }

  set mobilNoMaxLength(value) {
    this._mobileNoMaxLength = value;
  }

  get allowOpenSenderid() {
    return this._allowOpenSenderid;
  }
  set allowOpenSenderid(value) {
    this._allowOpenSenderid = value;
  }

  get maxReportDaysLimit() {
    return this._maxReportDaysLimit;
  }
  set maxReportDaysLimit(value) {
    this._maxReportDaysLimit = value;
  }

  get allowCustomOptout() {
    return this._allowCustomOptout;
  }
  set allowCustomOptout(value) {
    this._allowCustomOptout = value;
  }

  get customOptoutContent() {
    return this._customOptoutContent;
  }
  set customOptoutContent(value) {
    this._customOptoutContent = value;
  }

  get dltTemplateValidation() {
    return this._dltTemplateValidation;
  }
}

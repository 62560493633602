import { MiniCardComponent } from './mini-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [MiniCardComponent],
  exports: [MiniCardComponent]
})
export class MiniCardModule {}

import { Component, OnInit, Input } from '@angular/core';
import { Theme } from '../../service/guiTheme';
import { GUIService } from '../../service';

@Component({
  selector: 'c-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private guiService: GUIService) {
  }
  today: number = Date.now();

  @Input('gui-theme')
  theme: Theme = new Theme();
  tempTheme: Theme = new Theme();
  ngOnInit() {
  }

  ngOnChanges() {
    if (this.theme == undefined) {
      this.theme = this.tempTheme;
    }
  }
  setColor(tColor) {
    try {
      return this.guiService.setColor(tColor);
    } catch (error) {
      console.log(error);
    }
  }

  setTheme(tColor, sColor?) {
    try {
      return this.guiService.setTheme(tColor, sColor);
    } catch (error) {
      console.log(error);
    }
  }


}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { routing } from './app.routing';
import { CardModule, InputModule, ProgressBarModule, UiSwitchModule } from './Component/component';
import { TranslateModule, TranslateLoader, TranslateStaticLoader } from 'ng2-translate/ng2-translate';
import { Md2Module } from 'md2';
import { TabViewModule, GrowlModule } from 'primeng/primeng';
import { SharedModule } from './shared/shared.module';
export function createTranslateLoader(http: Http) {
  return new TranslateStaticLoader(http, './assets/i18n', '.json');
}
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedServiceModule } from './shared/shared-service.module';
import { TreeviewModule } from 'ngx-treeview';
import { SmsParametersService } from './message/sms-parameters.service';
import { SendMessageService } from './Aggregator-module/send-message/send-message.service';
export function smsParamServiceFactory(param: SmsParametersService): Function {
  return () => { param.setParameters(); }; // => required, otherwise `this` won't work inside StartupService::load
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule,
    HttpModule,
    routing,
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [Http]
    }),
    Md2Module,
    TabViewModule, GrowlModule,
    CardModule, InputModule, ProgressBarModule,
    SharedModule,
    BrowserAnimationsModule,
    UiSwitchModule, SharedServiceModule,
    TreeviewModule.forRoot()
  ],
  providers: [SmsParametersService,SendMessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: smsParamServiceFactory,
      deps: [SmsParametersService],
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export class Theme {
    brandLogo = '';
    navigationDirection: string; // Don't give difault value
    themeColor = '#ffffff';
    shadeColor = '#FFFFFF';
    iconColor = '#ffffff';
    textColor = '#ffffff';
    headingColor = '';
    backgroundColor = '#ffffff';
    buttonColor = '';
    footerData = '';
    headerData = '';
    faviconIcon   = '';
    headerPosition = '';
    navigationPosition = '';
    bellColor = '#ffffff';
    navigationBarColor = '#ffffff';
    navigationTextColor = '#ffffff';
    borderColor = '#ffffff';
    applyHeaderBorder = 'no';
    applyFooterBorder = 'no';
    buttonMouseHoverColor = '#ffffff';
    navigationNameBackgroundColor = '#ffffff';
    progressBarColor = '#ffffff';
    applyButtonMouseHoverColor = 'no';
    navigationType = 'sidenav';
}

import { Component, OnInit, Input, Output, ViewChild, HostListener, ElementRef, forwardRef, Inject } from '@angular/core';
// import * as $ from 'jquery';
declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'back-to-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  @Input() position: number = 100;
  @Input() showSpeed: number = 500;
  @Input() moveSpeed: number = 1000;
 _color: any = "#830F0F";

  @ViewChild('baBackTop') _selector: ElementRef;

  get color() {
    return this._color;
  }
  @Input()
  set color(c) {
    this._color = c;
  }
  ngAfterViewInit() {
    this._onWindowScroll();
  }

  @HostListener('click')
  _onClick(): boolean {
    $('html, body').animate({ scrollTop: 0 }, { duration: this.moveSpeed });
    return false;
  }

  @HostListener('window:scroll')
  _onWindowScroll(): void {
    let el = this._selector.nativeElement;
    el.style.backgroundColor = this.color;
    window.pageYOffset > this.position ? $(el).fadeIn(this.showSpeed) : $(el).fadeOut(this.showSpeed);
  }
}

import { ElementRef, Injectable, ViewChild } from '@angular/core';
import {
  ApiService,
  GUIService,
  UserService,
  ParametersService
} from '../../shared/service';
import {
  apigetAllGroups,
  apiGetAllSenderid,
  apiGetAllTemplate,
  apiSendMessage,
  apiMessgaTypeList,
  apiUsersPlan,
  SMS_USER_LOOKUP,
  SEND_EXCEL_PLUGIN,
  apiGetAllDLTTemplateId
} from '../../message/message-api-constants';
import { apiPath } from '../../config';
import { apiViewProfile } from '../../common';
import { User } from '../../modules/manage-user/user';
import { MessageType } from './message-type';
import { Radio } from 'src/app/Component/lib/radio-button/radio';
import { SmsParametersService } from '../../message/sms-parameters.service';

@Injectable()
export class SendMessageService {
  private maxMobileLength: number = 13;
  private _maxCharLimit: number = 999;
  private _accountUsageType: String = 'service';
  private _currentBalance = 0.0;
  private _messageTypes: Array<Radio> = [];
  private _allowOpenSenderId: boolean = false;
  private static _headerTemplateList: Array<any> = [];
  private static _footerTemplateList: Array<any> = [];
  private static _messageTemplateList: Array<any> = [];
  private static _senderIdList: Array<any> = [];
  private service: Radio = new Radio();
  private promotional: Radio = new Radio();
  private static _defaultSenderId: any;
  private _currency: String;
  _defaultMessageType: any = 0;
  _allowLongSMS = true;
  unicodeMessageDivider = 67;
  nonUnicodeMessageDivider = 153;
  _allowCustomOptoutToUser = false;
  @ViewChild('dltlist') messageContent: ElementRef;
  private static _dltTemplateIdList: Array<any> = [];
  private static _defaultDLTTemplateId: any;
  private static _message: any;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    public guiService: GUIService,
    private parameter: ParametersService,
    public smsParameter: SmsParametersService
  ) { }

  get message() {
    return SendMessageService._message;
  }

  set message(value) {
    SendMessageService._message = value;
  }

  get allowCustomOptoutToUser() {
    return this._allowCustomOptoutToUser;
  }

  set allowCustomOptoutToUser(value) {
    this._allowCustomOptoutToUser = value;
  }

  isNumber(event, mobileno) {
    try {
      let key = event.which ? event.which : event.keyCode;
      if (typeof key === 'number' && key > 46) {
        if (
          String.fromCharCode(key).match(/^[0-9]+$/) != null &&
          mobileno.length <= this.maxMobileLength
        ) {
          return true;
        }
        else { return false; }
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  currentBalance() {
    try {
      this.apiService.postCall(
        apiPath + apiViewProfile,
        {},
        (response: any) => {
          let status = response['status'];
          if (status) {
            this.apiService.manageTokenAndSession(status);
            if (status['error-code'] && status['error-code'] === '000') {
              let user = response['user-profile-details'];
              if (user) {
                user.forEach(element => {
                  this._currentBalance = element['current-balance'];
                  this._currency = element['currency'];
                  if (this._currency) {
                    this.guiService.setServerMessage(
                      'info',
                      this.guiService.getTranslate('popup-details.sms.balance'),
                      this._currency + ' ' + this._currentBalance
                    );
                  } else {
                    this.guiService.setServerMessage(
                      'info',
                      this.guiService.getTranslate('popup-details.sms.balance'),
                      this._currentBalance
                    );
                  }
                });
              }
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  get userCurrentBalance() {
    return this._currentBalance;
  }

  // get displayBalance(){
  //   if (this._currency) {
  //     return this._currentBalance + " " + this._currency;
  //   } else {
  //     return this._currentBalance;
  //   }
  // }
  load() {
    this.loadSMSUserDetail();
    // this.loadUserDetail();
    this.loadDLTTemplateIDs();
    this.setServiceType();
    this.loadTemplateList();
    this.setCharacterLimit();
    this.loadMessageTypes();
    this.loadSenderIDs();
    this.currentBalance();
    if(this.smsParameter.dltTemplateValidation && this.smsParameter.dltTemplateValidation == 'on'){
      this.loadDLTTemplateIDs();
    }
  }

  loadUserDetail() {
    try {
      this.apiService.postCall(
        apiPath + apiViewProfile,
        {},
        (response: any) => {
          let status = response['status'];
          if (status) {
            this.apiService.manageTokenAndSession(status);
            if (status['error-code'] && status['error-code'] === '000') {
              this.setUser(response['user-profile-details']);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  loadMessageTypes() {
    try {
      this.apiService.postCall(
        apiPath + apiMessgaTypeList,
        {},
        (response: any) => {
          let status = response['status'];
          if (status) {
            this.apiService.manageTokenAndSession(status);
            if (status['error-code'] && status['error-code'] === '000') {
              this.setMessageType(response['message-types']);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  setUser(user) {
    try {
      if (user) {
        user.forEach(element => {
          // this._currentBalance = element['current-balance'];
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  setMessageType(messageTypeList) {
    try {
      if (messageTypeList) {
        this._messageTypes = [];
        messageTypeList.forEach(element => {
          if (element['status'].toUpperCase() === 'active'.toUpperCase()) {
            let messageType = new Radio();
            messageType.id = element['id'];
            messageType.value = element['id'];
            messageType.label = element['message-type'];
            if (element['default'].toUpperCase() === 'yes'.toUpperCase()) {
              messageType.isChecked = 'true';
              this._defaultMessageType = element['id'];
            }
            this._messageTypes.push(messageType);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  get defaultMessageType() {
    return this._defaultMessageType;
  }
  setCharacterLimit() {
    try {
      this.apiService.postCall(apiPath + apiUsersPlan, {}, (response: any) => {
        let status = response['status'];
        if (status) {
          this.apiService.manageTokenAndSession(status);
          if (status['error-code'] && status['error-code'] === '000') {
            let planDetails = response['plan'];
            if (planDetails) {
              this._maxCharLimit = planDetails['character-limit'];
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  get maxCharLimit() {
    return this._maxCharLimit;
  }

  get messageTypes() {
    return this._messageTypes;
  }

  messageLanguageOptions = [
    {
      label: this.guiService.getTranslate(
        'send-message.message-language-english'
      ),
      id: 1,
      value: 'english',
      isChecked: 'true'
    },
    {
      label: this.guiService.getTranslate(
        'send-message.message-language-unicode'
      ),
      id: 2,
      value: 'unicode'
    }
  ];

  messageServiceOptions = [];

  get allowOpenSenderId() {
    return this._allowOpenSenderId;
  }

  set allowOpenSenderId(value) {
    this._allowOpenSenderId = value;
  }

  loadTemplateList() {
    try {
      this.apiService.postCall(
        apiPath + apiGetAllTemplate,
        {},
        (response: any) => {
          let status = response.status;
          if (status) {
            this.apiService.manageTokenAndSession(status);
            if (status['error-code'] === '000') {
              this.setTemplateList(response.templates);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  setTemplateList(templateList: any) {
    try {
      if (templateList !== undefined) {
        if (templateList.length !== undefined && templateList.length > 0) {
          templateList.forEach((template: any) => {
            if (template['type'].toUpperCase() === 'Message'.toUpperCase()) {
              this.messageTemplateList.push({
                name: template['name'],
                value: template
              });
            }
            if (template['type'].toUpperCase() === 'Header'.toUpperCase()) {
              this.headerTemplateList.push({
                name: template['name'],
                value: template
              });
            }
            if (template['type'].toUpperCase() === 'Footer'.toUpperCase()) {
              this.footerTemplateList.push({
                name: template['name'],
                value: template
              });
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  get messageTemplateList() {
    return SendMessageService._messageTemplateList;
  }

  get footerTemplateList() {
    return SendMessageService._footerTemplateList;
  }

  get headerTemplateList() {
    return SendMessageService._headerTemplateList;
  }

  loadSenderIDs() {
    try {
      let body = { 'senderid-status': ['approved'] };
      this.apiService.postCall(
        apiPath + apiGetAllSenderid,
        body,
        (response: any) => {
          let status = response.status;
          if (status) {
            this.apiService.manageTokenAndSession(status);
            if (status['error-code'] === '000') {
              this.setSenderIdList(response['senderid-details']);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
  setSenderIdList(senderIdList: any) {
    try {
      if (senderIdList !== undefined) {
        if (senderIdList.length !== undefined && senderIdList.length > 0) {
          senderIdList.forEach((senderid: any) => {
            if (senderid['is-default'].toUpperCase() === 'yes'.toUpperCase()) {
              SendMessageService._defaultSenderId = senderid;
            }
            SendMessageService._senderIdList.push({
              name: senderid['sender-id'],
              value: senderid
            });
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  get senderIdList() {
    return SendMessageService._senderIdList;
  }

  get dltTemplateIdList() {
    return SendMessageService._dltTemplateIdList;
  }

  get defaultDLTTemplateMessage(){
    return SendMessageService._message;
  }

  loadDLTTemplateIDs() {
    try {
      let body = { 'status': 'active'};
      console.log('===> ', apiPath + apiGetAllDLTTemplateId,body);
      this.apiService.postCall(
        apiPath + apiGetAllDLTTemplateId,
        body,
        (response: any) => {
          let status = response.status;
          if (status) {
            this.apiService.manageTokenAndSession(status);
            if (status['error-code'] === '000') {
              this.setDLTTemplateIdList(response['dlttemplateid-details']);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  setDLTTemplateIdList(dltTemplateIdList: any) {
    try {
      SendMessageService._dltTemplateIdList = [];
      if (dltTemplateIdList !== undefined) {
        if (dltTemplateIdList.length !== undefined && dltTemplateIdList.length > 0) {
          dltTemplateIdList.forEach((dlttemplateid: any) => {
            if (dlttemplateid['is-default'].toUpperCase() === 'yes'.toUpperCase()) {
              SendMessageService._defaultDLTTemplateId = dlttemplateid;
              SendMessageService._message = dlttemplateid['template'];
            }
            SendMessageService._dltTemplateIdList.push({
              name: dlttemplateid['name'] + "(" + dlttemplateid['dlttemplateid'] + ")",
              value: dlttemplateid
            });
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  setServiceType() {
    this.messageServiceOptions = [];
    this.service.id = '1';
    this.service.label = this.guiService.getTranslate(
      'send-message.message-service-type-service'
    );
    this.service.value = 'service';
    this.promotional.id = '2';
    this.promotional.label = this.guiService.getTranslate(
      'send-message.message-service-type-promotional'
    );
    this.promotional.value = 'promotional';
    this.messageServiceOptions.push(this.service);
    this.messageServiceOptions.push(this.promotional);
  }

  setMessageServiceOption(userService) {
    try {
      if (userService) {
        if (userService.toUpperCase() === 'service'.toUpperCase()) {
          this.service.isChecked = 'true';
          this.promotional.isDisabled = 'true';
        } else if (userService.toUpperCase() === 'promotional'.toUpperCase()) {
          this.promotional.isChecked = 'true';
          this.service.isDisabled = 'true';
        } else {
          if (
            userService.toUpperCase().indexOf('service'.toUpperCase()) !== -1 &&
            userService.toUpperCase().indexOf('promotional'.toUpperCase()) !==
            -1
          ) {
          }
          this.accountUsageType = 'service';
          this.service.isChecked = 'true';
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  get defaultSenderId() {
    return SendMessageService._defaultSenderId;
  }

  get defaultDLTTemplateId() {
    return SendMessageService._defaultDLTTemplateId;
  }

  loadSMSUserDetail() {
    try {
      this.apiService.postCall(
        apiPath + SMS_USER_LOOKUP,
        {},
        (response: any) => {
          let status = response['status'];
          if (status) {
            this.apiService.manageTokenAndSession(status);
            if (status['error-code'] && status['error-code'] === '000') {
              this.setUsersSmsDetail(response['user']);
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  setUsersSmsDetail(user) {
    try {
      if (user) {
        this.allowCustomOptoutToUser = this.smsParameter.allowCustomOptout && user['allow-custom-optout'] && user['allow-custom-optout'].toString() == 'yes' ? true : false;
        this.allowOpenSenderId =
          user['support-dynamic-senderid'] &&
            user['support-dynamic-senderid'].toString() === 'yes'
            ? true
            : false;
        this._accountUsageType = user['account-type'];
        this.allowLongSms =
          user['allow-long-sms'] && user['allow-long-sms'].toString() === 'yes'
            ? true
            : false;
        this.setMessageServiceOption(this._accountUsageType);
        this.setOptOutContentLength();
      }
    } catch (error) {
      console.log(error);
    }
  }
  optOutContentLength: number = 0;
  setOptOutContentLength() {
    try {
      this.optOutContentLength = 0;
      if (this.allowCustomOptoutToUser && this.smsParameter.customOptoutContent && this.smsParameter.customOptoutContent != '') {
        this.optOutContentLength = this.smsParameter.customOptoutContent.length + 1;
      }
    } catch (error) {
      console.log(error);
    }
  }
  get accountUsageType() {
    return this._accountUsageType;
  }

  set accountUsageType(v) {
    this._accountUsageType = v;
  }

  get allowLongSms() {
    return this._allowLongSMS;
  }

  set allowLongSms(v) {
    this._allowLongSMS = v;
  }
  isUserActive() {
    return this.userService.getUserCurrentStatus() == 'active';
  }
  showMessage() {
    try {
      let status = this.userService.getUserCurrentStatus();
      if (status && status == 'awaiting') {
        let notificationMessage = this.parameter.dashboardMessage;
        if (notificationMessage) {
          this.guiService.setServerMessage('error', 'Information', notificationMessage);
          // this.messageFlag = true;
        }
      } else if (status && status == 'inactive') {
        this.guiService.setMessage('error', 'popup-details.common.warn', 'inactive-user');
      }
    } catch (error) {

    }
  }
}

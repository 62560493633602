export const apiAccountInfomation = '';
export const apiSendMessage = 'send/message';

export const apigetAllGroups = 'group/lookup';
export const apicreateGroup = 'group/create';
export const apieditGroup = 'group/update';
export const apideleteGroup = 'group/delete';

export const apiGetAllTemplate = 'template/lookup';
export const apiDeleteTemplate = 'template/delete';
export const apiEditTemplate = 'template/update';
export const apiCreateTemplate = 'template/create';

export const getApiKey = 'apikey/lookup';
export const deleteApiKey = 'apikey/delete';
export const addApiKey = 'apikey/insert';
export const generateApiKey = 'apikey/generateapikey';

export const apiCreateNewAddressbook = 'addressbook/create';
export const apiEditNewAddressbook = 'addressbook/update';
export const apigetAllAddressBook = 'addressbook/lookup';
export const apiDeleteAddressbook = 'addressbook/delete';
export const apiImportAddressBook = 'addressbook/import/address';

export const apiMessgaTypeList = 'messagetype/list';

export const apigetCRMAddress = 'crm/contacts';
export const apisetCRMWishes = 'crm/reminder';
export const apiGetUserCRMStatus = 'crm/statuslookup';
export const apisetUserCRMStatus = 'crm/changestatus';
export const apisetUserCRMWishFormat = 'crm/messageformat';
export const apiGetUsesrCRMMessage = 'crm/messagelookup';
export const apiUsersPlan = 'smsplan';

export const apiSMSParameters = 'sms/parameters';
export const apiImportSms = 'send/import/sms';
export const apiGroupSms = 'send/group/message';

export const apiGetAllSenderid = 'senderid/lookup';
export const apiSenderIdSetDefault = 'senderid/default';
export const apiSenderIdRequest = 'senderid/create';
export const apiImportSenderId = 'senderid/import';
export const apiImportDltTemplates ='dlttemplateid/import'
export const apiGetAllDLTTemplateId = 'dlttemplateid/lookup';
export const apiUpdateDltEntityId = "smsuser/update/dltentityid"

export const apigetSummaryLogReport = 'report/summary';

export const apiWeeklyUsageReport = 'lastweekusage/lookup';

export const apiMessageLogReport = 'report/messagelog';
export const apiPauseResumeGetList = 'pauseresume/lookup';
export const apiPauseResumeUpdate = 'pauseresume/update';
export const apiPurgeSMSGetList = 'purgesms/lookup';
export const apiPurgeSMS = 'purgesms/delete';

export const apiKeywordDetailReport = 'report/keyword/details';
export const apiKeywordSummaryReport = 'report/keyword/summary';

export const apiGetSourceList = 'source/lookup';
export const apiGetKeywordList = 'keyword/lookup';

export const SMS_PARAMETERS_LOOKUP = 'smsparameters/lookup';
export const SMS_USER_LOOKUP = 'smsuser/lookup';
export const apiGetSubkeywordList = 'subkeyword/lookup';

export const ADD_WHITEBLACKLIST_MOBILE = 'whiteblacklist/add';
export const UPDATE_WHITEBLACKLIST_MOBILE = 'whiteblacklist/update';
export const GET_WHITEBLACKLIST_MOBILE = 'whiteblacklist/lookup';
export const IMPORT_WHITEBLACKLIST_MOBILE = 'whiteblacklist/import';

export const UPDATE_KEYWORD = 'keyword/update';
export const API_KEYWORD_SEPARATOR_LOOKUP = 'keyword/data/separator/lookup';
export const API_KEYWORD_SEPARATOR_ADD = 'keyword/data/separator/insert';
export const API_KEYWORD_SEPARATOR_DELETE = 'keyword/data/separator/delete';

export const API_KEYWORD_INTEGRATION_LOOKUP = 'keywordintegration/lookup';
export const API_KEYWORD_INTEGRATION_ADD = 'keywordintegration/add';
export const API_KEYWORD_INTEGRATION_EDIT = 'keywordintegration/edit';
export const API_KEYWORD_INTEGRATION_DELETE = 'keywordintegration/delete';

export const API_TWOWAY_INBOX_PROPERTIES = '/inbox/properties/lookup';
export const API_SEND_AS_EMAIL = 'email/insert';
export const API_GET_SEND_AS_EMAIL = 'email/get';
export const API_RESEND_REPORT_AS_EMAIL = 'email/resend';
export const API_GET_CRM_STATUS_REPORT = 'report/utility/crmstatus';

export const API_CUSTOM_EXCEL_SMS = 'send/excel/sms';
export const API_GET_RESEND_DETAILS = 'report/resend';
export const SMS_USER_ADD = 'smsuser/create';
export const SMS_USER_EDIT = 'smsuser/update';
export const SMS_SUBUSER_LOOKUP = 'smsuser/subuser/lookup';
export const SEND_EXCEL_PLUGIN = 'send/excelplugin';
export const API_GET_SCHEDULE_SMS_SUMMARY = 'report/schedule/sms';
export const API_PURGE_SCHEDULE_SMS = 'report/schedule/sms/purge';
export const apiGetMessageOverrideSettings = 'messageoverride/lookup';
export const apiInsertMessageOverrideSettings = 'messageoverride/create';
export const apiUpdateMessageOverrideSettings = 'messageoverride/update';
export const apiDeleteMessageOverrideSettings = 'messageoverride/delete';

export const apiGetAllDLTTemplate = 'dlttemplateid/lookup';
export const apiAddDLTTemplate = 'dlttemplateid/create';
export const apiEditDLTTemplate = 'dlttemplateid/update';
export const apiDeleteDLTTemplate = 'dlttemplateid/delete';
import {
  DASHBOARD,
  INBOX_KEYWORD_VIEW,
  SEND_EMAIL_LIST
} from 'src/app/message/message-url-constants';

// export const otherRoutes = [
//   {
//     path: '',
//     loadChildren: 'src/app/message/message.module#MessageModule'
//   }
// ];


export const apiPath = 'https://sms.vas.co.zw/client/portal-api/';

// export const apiRoute = 'sms';
export const firstPageAfterLogin = '/' + DASHBOARD; //
export const processPlanAction = 'smsplan';
export const isProcessPlan = false;
export const processUserAction = 'smsuser';
export const isProcessUser = false;
export const JWT_KEY = 'client';
export const hierarchy = 'reseller';
export const twolevel = 'aggregator';
export const ACTION_VIEW = 'view';
export const ACTION_ADD = 'add';
export const ACTION_EDIT = 'edit';
export const isProcessRate = false;
export const processRateAction = 'sms/detail-rate';
export const RATE_KEY = 'process_rate';
export const PLAN_KEY = 'process_plan';
export const USER_KEY = 'process_user';
export const defaultLanguage = { label: 'English', value: 'en', flag: '' };
export const portalName = 'CLIENT-Portal';
export const loginMessageFlag = true;
export const loginMessage =
  // 'Note: For reseller as client login use clientid@resellerid';
  'Note: For subUser login use subuser@mainuser';
export const KEY_FOR_USER_DETAIL_STORAGE = 'cfw';

export class LoginInfo {
  userType: string = 'subuser';
  afterLoginPage: string = '/' + 'sendsms';
}

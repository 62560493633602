import {
  Component,
  Input,
  forwardRef,
  OnInit,
  HostListener,
  ElementRef,
  Directive,
  AfterViewInit,
  ViewChild,
  OnChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
const noop = () => {};

@Component({
  selector: 'cInput',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextComponent),
      multi: true
    }
  ]
})
export class TextComponent implements ControlValueAccessor, AfterViewInit, OnInit, OnChanges {
  onChange: any = () => {};
  onTouched: any = () => {};
  @Input('textColor') textColor: any = '#5264AE';

  @Input('align') align: any = 'left';

  @Input('minlength') minlength: number = 0;

  @Input('maxlength') maxlength: number = 1000000;

  @Input('min') min: number = 0;

  @Input('max') max: number = 1000000000;

  @Input('required') required: boolean = false;

  @Input('value') _value: any = '';

  @Input('type') textType: any = 'text';

  @Input('disabled') isDisable: boolean = false;

  @Input('placeholder') placeholder: any;

  @Input('focus') autoFocus: boolean = false;
  @ViewChild('text') text: ElementRef;

  // @Input('icon') icon: any;

  private innerValue: any = '';
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  isValidAfter(e) {
    if (this.textType) {
      if (this.textType === 'number') {
        let value = e.target.value;
        try {
          if (parseInt(value) < this.min) {
            event.preventDefault();
          }
          if (parseInt(value) >= this.max) {
            event.preventDefault();
          }
        } catch (error) {
          console.log(error);
          return true;
        }
      }
    }
    return true;
  }

  isValid(e) {
    if (this.textType) {
      if (this.textType === 'number') {
        let value = e.target.value;
        try {
          if (parseInt(this.value) < this.min) {
            return false;
          }
          if (parseInt(this.value) >= this.max) {
            return false;
          }
        } catch (error) {
          console.log(error);
          return true;
        }
      }
    }
    return true;
  }

  ngOnChanges() {}
  isValidValue() {}

  ngAfterViewInit() {
    this.focus();
  }

  ngOnInit() {
    if (this.textType) {
      if (this.textType === 'number') {
        this.maxlength = this.max;
        this.minlength = this.min;
      }
    }
  }

  get value(): any {
    return this.innerValue;
  }

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  constructor() {}

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setColor(tColor) {
    try {
      return {
        color: tColor
      };
    } catch (error) {
      console.log(error);
    }
  }


  focus() {
    try {
      if (this.autoFocus) {
        this.text.nativeElement.autofocus = true;
      }
    } catch (error) {
      console.log(error);
    }
  }
}


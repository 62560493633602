import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { CardTitleComponent } from './card-title/card-title.component';
import { CardContent } from './card-content/card-content';
import {HeaderAction} from './header-action';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CardComponent, CardTitleComponent, CardContent, HeaderAction],
  exports: [CardComponent, CardTitleComponent, CardContent, HeaderAction]
})
export class CardModule { }

import { Inject, Injectable, Injector } from "@angular/core";
import { Headers, Http, Response, URLSearchParams } from "@angular/http";
import { api_url, JWT_KEY_FOR_SYSTEM } from "../../common";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { Router } from "@angular/router";

@Injectable()
export class ApiService {
  constructor(@Inject(Http) private http: Http, private injector: Injector) { }

  get tokenKey() {
    return JWT_KEY_FOR_SYSTEM + "jwtToken";
  }

  get tokenKey1() {
    return JWT_KEY_FOR_SYSTEM + "jwtToken1";
  }

  get sessionKey() {
    return JWT_KEY_FOR_SYSTEM + "jwtSession";
  }

  private setHeaders(): Headers {
    const headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json"
    };
    if (this.getToken) {
      headersConfig["token"] = `${this.getToken}`;
    }
    if (this.getSessionId) {
      headersConfig["session-id"] = `${this.getSessionId}`;
    }
    return new Headers(headersConfig);
  }
  get getToken(): any {
    if (typeof window.localStorage[this.tokenKey] != "string")
      return window.localStorage[this.tokenKey];
    else {
      return window.atob(window.localStorage[this.tokenKey]);
    }
    // return window.localStorage[this.tokenKey];
  }

  get getToken1(): any {
    if (typeof window.localStorage[this.tokenKey1] != "string")
      return window.localStorage[this.tokenKey1];
    else {
      return window.atob(window.localStorage[this.tokenKey1]);
    }
    // return window.localStorage[this.tokenKey1];
  }

  manageToken(token: string) {
    if (
      token === undefined ||
      token == null ||
      token === "unknown" ||
      token.trim() === ""
    ) {
      if (this.getToken === this.getToken1) {
        this.destroyCurrentSession();
      } else {
        window.localStorage[this.tokenKey] = window.btoa(this.getToken1);
      }
    } else {
      this.saveToken1(this.getToken);
      window.localStorage[this.tokenKey] = window.btoa(token);
    }
  }

  saveToken1(token1: string) {
    window.localStorage[this.tokenKey1] = window.btoa(token1);
  }

  destroyToken() {
    window.localStorage.removeItem(this.tokenKey);
    window.localStorage.removeItem(this.tokenKey1);
  }

  get getSessionId(): any {
    if (typeof window.localStorage[this.sessionKey] != "string")
      return window.localStorage[this.sessionKey];
    else {
      return window.atob(window.localStorage[this.sessionKey]);
    }
  }

  saveSessionId(sessionId: string) {
    window.localStorage[this.sessionKey] = window.btoa(sessionId);
  }

  destroySessionId() {
    window.localStorage.removeItem(this.sessionKey);
  }

  destroyCurrentSession() {
    this.destroyToken();
    this.destroySessionId();
  }

  manageTokenAndSession(serverStatus) {
    try {
      if (serverStatus) {
        if (
          serverStatus["session-id"] &&
          serverStatus["session-id"].trim() !== "" &&
          serverStatus["session-id"] !== "unknown"
        ) {
          this.saveSessionId(serverStatus["session-id"]);
          this.manageToken(serverStatus["token"]);
        } else {
          this.destroyCurrentSession();
          this.router.navigate(["/login"]);
        }
      } else {
        this.destroyCurrentSession();
        this.router.navigate(["/login"]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  private formatErrors(error: any) {
    try {
      return error.text();
    } catch (error) {
      console.log(error);
    }
  }

  post(path: string, body: Object): Observable<any> {
    try {
      return this.http
        .post(`${path}`, body, { headers: this.setHeaders() })
        .map((res: Response) => res.json())
        .catch(this.formatErrors);
    } catch (error) {
      console.log(error);
    }
  }

  postCall(url: any, body: any, getResponse: any) {
    let headers = new Headers();
    let start: string = new Date().getTime().toString();
    headers.append("Content-Type", "application/json");
    headers.append("session-id", this.getSessionId);
    headers.append("token", this.getToken);
    headers.append("request_start_time", start);
    this.http
      .post(url, body, {
        headers: headers
      })
      .map(response => response.json())
      .share()
      .subscribe(
        response => {
          if (response && response["status"]) {
            getResponse(response);
          } else {
            const body = {
              "error-code": response["error-code"],
              "error-status": response["error-status"],
              "error-description": response["error-description"],
              "session-id": response["session-id"],
              token: response["token"],
              status: response
            };
            getResponse(body);
          }
        },
        err => getResponse(this.handleError(err))
      );
  }

  private handleError(error: any) {
    const description = "There was an error: " + error.status;
    const errors: any = {};
    errors["error-code"] = error.status;
    errors["error-status"] = error.statusText;
    errors["error-description"] = description;
    const status = { status: errors };
    return status;
  }

  checkValidSessionToken() {
    if (
      this.getSessionId &&
      this.getSessionId.trim() !== "" &&
      this.getToken &&
      this.getToken.trim() !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  public get router(): Router {
    // this creates router property on your service.
    return this.injector.get(Router);
  }
}

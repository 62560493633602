import { Component, OnInit, Input ,ElementRef,OnChanges} from '@angular/core';

@Component({
  selector: 'card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.css'],
  host: {
    '[class.card-title]': 'true'
  }
})
export class CardTitleComponent implements OnChanges {

@Input() borderColor: string = '#f0f0f0';
constructor(private element: ElementRef) {  
  this.element.nativeElement.style.borderBottom = '1px solid';
  this.element.nativeElement.style.borderBottomColor = this.borderColor;
  this.element.nativeElement.style.display = 'block';
  this.element.nativeElement.style.marginBottom = '16px';
  this.element.nativeElement.style.paddingBottom = '8px';
}

ngOnChanges() {
  this.element.nativeElement.style.borderBottomColor = this.borderColor;
}

}

import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { domain, api_url, landingPages, getNavigation, getLanguages } from '../../common';
import { ApiService } from './api.service';
import { Theme } from './guiTheme';
import { Title, Meta } from '@angular/platform-browser';
import { Message } from 'primeng/primeng';
import { TranslateService } from 'ng2-translate';
import { Menubar } from './menu-bar';
import { Location } from '@angular/common';
import { defaultLanguage } from 'src/app/config';
declare var $: any;
import { filter, map, catchError } from 'rxjs/operators';

@Injectable()
export class GUIService {
    _theme: Theme;
    pages: any;
    navigationMenus: Menubar[] = [];
    _languageList: Array<any> = [defaultLanguage];
    _language: Array<string> = [defaultLanguage['value']];
    translatedLabel: string;
    _languagePattern = /en|fr|hi/;
    public _loading = false;
    public growlmsgs: Message[];
    constructor(public translate: TranslateService, public _location: Location,
        public apiService: ApiService, public titleService: Title, public metaService: Meta) {
    }

    changeLanguage(lang) {
        this.translate.use(lang);
    }

    public setTitle(newTitle: string) {
        try {
            this.titleService.setTitle(newTitle);
        } catch (error) {
            console.log(error);
        }
    }

    get activeColor() {
        return '#008000';
    }
    public setMetaKeywords(newKeyword: string) {
        try {
            this.metaService.addTag({ name: 'keywords', content: newKeyword });
        } catch (error) {
            console.log(error);
        }
    }

    public setMetaDescription(newDesc: string) {
        try {
            this.metaService.addTag({ name: 'description', content: newDesc });
        } catch (error) {
            console.log(error);
        }
    }

    getGUI(): Observable<any> {
        try {
            const body = {
                'domain': domain,
                'request-by': 'login'
            }
            return this.apiService.post(api_url + landingPages, body).map(data => data);
        } catch (error) {
            console.log(error);
        }
    }

    getNavigation(): Observable<any> {
        try {
            return this.apiService.post(api_url + getNavigation, domain).map(data => data);
        } catch (error) {
            console.log(error);
        }
    }

    setColor(tColor) {
        try {
            return {
                'color': tColor,
            };
        } catch (error) {
            console.log(error);
        }
    }

    setTheme(tColor, sColor?) {
        try {
            if (sColor == undefined || sColor == null) {
                this.applyColorOnHover();
                return {
                    'background-color': tColor,
                };
            } else {
                return {
                    'background': 'linear-gradient(to left,' + tColor + ',' + sColor + ')',
                };
            }
        } catch (error) {
            console.log(error);
        }
    }



    setGUITheme(themeData: any) {
        try {
            if (themeData) {
                this.theme = new Theme();
                this.theme.backgroundColor = themeData['background-color'];
                this.theme.buttonColor = themeData['button-color'];
                this.theme.headingColor = themeData['heading-color'];
                this.theme.iconColor = themeData['icon-color'];
                this.theme.navigationDirection = themeData['navigation-direction'];
                this.theme.shadeColor = themeData['shade-color'];
                this.theme.textColor = themeData['text-color'];
                this.theme.themeColor = themeData['theme-color'];
                this.theme.brandLogo = themeData['brand-logo'];
                this.theme.footerData = themeData['footer-text'];
                this.theme.faviconIcon = themeData['favicon-icon'];
                this.theme.navigationPosition = themeData['navigation-position'];
                this.theme.headerPosition = themeData['header-position'];
                this.theme.headerData = themeData['header-text'];
                this.theme.bellColor = themeData['bell-color'];
                this.theme.navigationBarColor = themeData['navigationbar-color'];
                this.theme.navigationTextColor = themeData['navigationtext-color'];
                this.theme.borderColor = themeData['border-color'];
                this.theme.applyHeaderBorder = themeData['apply-header'];
                this.theme.applyFooterBorder = themeData['apply-footer'];
                this.theme.buttonMouseHoverColor = themeData['button-mouse-hover-color'];
                this.theme.navigationNameBackgroundColor = themeData['navigation-name-background-color'];
                this.theme.progressBarColor = themeData['progress-bar-color'];
                this.theme.applyButtonMouseHoverColor = themeData['apply-button-mouse-hover-color'];
                this.theme.navigationType = themeData['navigation-type'];
            }
        } catch (error) {
            console.log(error);
        }
    }
    getGUITheme() {
        return this.theme;
    }

    setGUINavigation(menus: Menubar[]) {
        try {
            if (menus) {
                this.navigationMenus = menus;
            }
        } catch (error) {
            console.log(error);
        }
    }
    getGuiNavigation() {
        return this.navigationMenus;
    }


    getMessage() {
        return this.growlmsgs;
    }
    setGrowlmsgs(msgs: any) {
        this.growlmsgs = msgs;
    }

    setMessage(severityTemp, summaryKey, messageKey) {
        try {
            let msgs: Message[] = [];
            let message = messageKey;
            let summary = summaryKey;
            this.translate.get(messageKey).subscribe((res: string) => {
                message = res;
            });
            this.translate.get(summaryKey).subscribe((res: string) => {
                summary = res;
            });
            msgs.push({ severity: severityTemp, summary: summary, detail: message });
            this.setGrowlmsgs(msgs);
        } catch (error) {
            console.log(error);
        }
    }

    setServerMessage(severityTemp, summaryKey, messageKey) {
        try {
            const msgs: Message[] = [];
            msgs.push({ severity: severityTemp, summary: summaryKey, detail: messageKey });
            this.setGrowlmsgs(msgs);
        } catch (error) {
            console.log(error);
        }
    }


    textTranslater(label: string) {
        this.translate.get(label).subscribe((res: string) => {
            this.translatedLabel = res;
        });

    }
    getTranslate(label) {
        this.textTranslater(label);
        return this.translatedLabel;
    }
    getTranslateParam(label, param: string) {
        this.textTranslaterParam(label, param);
        return this.translatedLabel;
    }
    textTranslaterParam(label: string, param: string) {
        this.translate.get(label, { value: param }).subscribe((res: string) => {
            this.translatedLabel = res;
        });
    }
    getGUIPages() {
        return this.pages;
    }

    setGUIPages(pages) {
        try {
            if (pages) {
                this.pages = pages;
            }
        } catch (error) {
            console.log(error);
        }
    }
    goBack() {
        this._location.back();
    }

    translateWithParam(expr: string, params?: any) {
        try {
            let data: any;
            this.translate.get(expr, params).subscribe((res: string) => {
                data = res;
            });
            return data;
        } catch (error) {
            console.log(error);
        }
    }
    setGUI() {
        try {
            this.getGUI().subscribe(
                data => {
                    this.setGUIPages(data['pages']);
                    this.setGUITheme(data['gui-theme']);
                                    }
            );
        } catch (error) {
            console.log(error);
        }
    }

    set language(value: any) {
        this._language = value;
    }
    get language() {
        return this._language;
    }
    set languageList(value: any) {
        this._languageList = value;
    }
    get languageList() {
        return this._languageList;
    }
    get defaultLanguage() {
        return this.language[0];
    };
    get languagePattern() {
        return this._languagePattern;
    }
    setLanguage() {
        try {

            this.apiService.postCall( api_url + getLanguages, {}, (getResponse: any) => {
                if (getResponse) {
                    const lang = getResponse['languages'];
                    if (lang) {
                        this.languageList = [];
                        this.language = [];
                        lang.forEach(element => {
                            if (element['is-enable'] == 'enable') {
                                this.languageList.push({
                                    'label': element['display-name'],
                                    'value': element['translate-key'],
                                    'flag': element['country-flag'],
                                });
                                this.language.push(element['translate-key']);
                            }
                        });
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }



    get loading() {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
    }
    startProgress() {
        try {
            this.loading = true;
            const divId = document.getElementById('router-content');
            if (divId instanceof HTMLElement) {
                divId.style.opacity = '0.4';
            }
        } catch (error) {
            console.log(error);
        }
    }

    stopProgress() {
        try {
          const divId = document.getElementById('router-content');
            if (divId instanceof HTMLElement) {
                divId.style.opacity = '1';
            }
            this.loading = false;
        } catch (error) {
            console.log(error);
        }
    }

    applyColorOnHover() {
        try {
            if (this.theme && this.theme.applyButtonMouseHoverColor == 'yes') {
                let abc = this.theme;
                let y = document.getElementsByTagName("button");
                $(y).mouseover(function (e) {
                    if ($(this) && $(this)[0] && ($(this)[0].className != 'user-image')) {
                        if ($(this)[0].style) {
                            $(this)[0].style.backgroundColor = abc.buttonMouseHoverColor;
                        }
                    }
                });
                $(y).mouseleave(function (e) {
                    if ($(this) && $(this)[0] && ($(this)[0].className != 'user-image')) {
                        if ($(this)[0].style) {
                            $(this)[0].style.backgroundColor = abc.buttonColor;
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    set theme(newTheme: Theme) {
        this._theme = newTheme;
    }
    get theme() {
        return this._theme;
    }
}





import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { menubar } from '../navigation-bar/menubar';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'preview-c-sidenav',
    templateUrl: './preview-navigation-bar.component.html',
    styleUrls: ['./preview-navigation-bar.component.css']
})
export class PreviewNavigationBarComponent implements OnInit {

    @Input() public menuList: menubar[];
    @Input() public iconColor: String = "#f47f41";
    @Input() public direction: String;
    @Input() public textColor: String = "#f47f41";
    @Input() public separateScroll: boolean;
    @Input() public navigationBackgroundColor: string = "#ffffff";

    constructor(private router: Router, private elRef: ElementRef, private activeRouter: ActivatedRoute) {
    }



    ngOnChanges() {
        setTimeout(() => this.defaultDisplay(), 50);
    }


    ngOnInit() {
    //    this.navigationPosition();
        if (window.screen.width < 800) {
            this.mobileView = true;
        } else {
            this.mobileView = false;
        }
    }

    setColor(tColor) {
        try {
            return {
                'color': tColor,
            };
        } catch (error) {
            console.log(error);
        }
    }

    childNavigatorTop(childmenu) {
        try {
            if (childmenu['children'] != undefined && childmenu['children'] != null && childmenu['children'].length > 0) {
                var direction = this.direction;
                $(document).ready(function () {
                    $('.dropdown-submenu a.third-child').on("click", (function (e) {
                        if (direction == 'left' || direction == 'top') {
                            if (!$(this).parent().siblings().children().has('ul').is(':visible')) {
                                $(this).parent().siblings().children('ul').hide();
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                            }
                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-right');
                            }
                        }
                        if (direction == 'right') {
                            if (!$(this).parent().siblings().children().has('ul').is(':visible')) {
                                $(this).parent().siblings().children('ul').hide();
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                            }
                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-left');
                            }
                        }

                        $(this).next('ul').show();
                        // e.stopPropagation();
                        // e.preventDefault();
                    }));
                });
            } else {
                // this.setNavigationPath(childmenu['target']);
                $(document).ready(function () {
                    if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                        $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                        $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                        $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                    }
                    if ($('.dropdown-submenu').parent().parent().hasClass('open')) {
                        $('.dropdown-submenu').parent().parent().removeClass('open');
                        $('.dropdown-submenu').parent().parent().children('a').children('span.caret').removeClass('caret-right');
                        $('.dropdown-submenu').parent().parent().children('a').children('span.caret').removeClass('caret-left');
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
    @ViewChild("subChild") subChild: any;
    navigatorTop(menu) {
        try {
            if (menu['children'] != undefined && menu['children'] != null && menu['children'].length > 0) {

                var direction = this.direction;
                $(document).ready(function () {

                    $('.preview .dropdown a.dropdown-toggle').on("click", (function (e) {

                        $('.dropdown-submenu').children('a').children('span.caret').removeClass('caret-right');
                        $('.dropdown-submenu').children('a').children('span.caret').removeClass('caret-left');
                        $('.dropdown-submenu').children('ul').hide();

                        if (direction == "left") {

                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-right');
                                $(this).children('ul').addClass('open');
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                            }
                        }
                        if (direction == "right") {
                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-left');
                                $(this).children('ul').addClass('open');
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                            }
                        }

                    }));
                });
            }
            else {
                $('.dropdown').siblings().children('a').children('span.caret').removeClass('caret-right');
                $('.dropdown').siblings().children('a').children('span.caret').removeClass('caret-left');
            }
        } catch (error) {
            console.log(error);
        }
    }

    defaultDisplay() {
        try {
            if (this.direction != undefined) {
                if (this.direction != 'top') {
                    let i = 0;
                    let list = document.getElementById("previewMySidenav").getElementsByTagName('ul');
                    for (i = 0; i < list.length; i++) {
                        list[i].style.backgroundColor = this.navigationBackgroundColor;
                    }
                } else if (this.direction == 'top') {
                    let i = 0;
                    let list = document.getElementById("previewMyNavbar").getElementsByTagName('ul');
                    for (i = 0; i < list.length; i++) {
                        list[i].style.backgroundColor = this.navigationBackgroundColor;
                    }
                }

                if (!this.mobileView) {
                    $('.sidenav-icon').css('display', 'inline-block');
                    if (this.direction == 'left') {
                        document.getElementById("preview-main-content").style.marginLeft = "150px";
                    }
                    else if (this.direction == 'right') {
                        document.getElementById("preview-main-content").style.marginRight = "150px";
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    navFlag: boolean = true;
    toggle() {
        try {
            if (this.navFlag) {
                this.closeNav();
            } else {
                this.openNav();
            }
        } catch (error) {
            console.log(error);
        }
    }

    openNav() {
        try {
            this.navFlag = true;
            if (!this.mobileView) {
                if (this.direction == 'left') {
                    document.getElementById("preview-main-content").style.marginLeft = "150px";
                }
                else if (this.direction == 'right') {
                    document.getElementById("preview-main-content").style.marginRight = "150px";
                }
                $('.preview-sidenav-text').css('display', 'inline-block');
            }
            else {
                document.getElementById("previewMySidenav").style.display = "block";
            }
        } catch (error) {
            console.log(error);
        }
    }
    textView: boolean = true;
    mobileView: boolean = true;
    closeNav() {
        try {
            this.navFlag = false;
            if (!this.mobileView) {
                if (this.direction == 'left') {
                    document.getElementById("preview-main-content").style.marginLeft = "20px";
                }
                else {
                    document.getElementById("preview-main-content").style.marginRight = "20px";
                }
                $('.preview-sidenav-text').css('display', 'none');
            } else {
                document.getElementById("previewMySidenav").style.display = "none";
            }
        } catch (error) {
            console.log(error);
        }
    }

    closeAll() {
        try {
            $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
            $('.dropdown-submenu a.third-child').parent().children('ul').hide();

            if ($('.dropdown-submenu').parent().parent().hasClass('open')) {
                $('.dropdown-submenu').parent().parent().removeClass('open');
                $('.dropdown-submenu').parent().parent().children('a').children('span.caret').removeClass('caret-right');
                $('.dropdown-submenu').parent().parent().children('a').children('span.caret').removeClass('caret-left');
            }

            if ($('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').hasClass('caret-right'))
                $('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').removeClass('caret-right');

            if ($('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').hasClass('caret-left'))
                $('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').removeClass('caret-left');
        } catch (error) {
            console.log(error);
        }
    }

    checkChild(menu) {
        try {
            if (menu['children'] != undefined && menu['children'] != null && menu['children'].length > 0) {
                var direction = this.direction;
                $(document).ready(function () {
                    $('.preview .dropdown a.dropdown-toggle').hover(function (e) {
                        if (!$(this).parent().has('ul').hasClass('open')) {
                            $(this).parent().has('ul').addClass('open');
                            $(this).parent().siblings().has('ul').removeClass('open');
                        }
                        if (direction == "left") {
                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-right');
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                            }
                        }
                        if (direction == "right") {
                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-left');
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                            }
                        }
                        if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                            $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                        }
                    });
                });
            } else {
                $('.dropdown a.dropdown-toggle').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                $('.dropdown a.dropdown-toggle').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                    $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                    $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                    $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    checkSubChild(childmenu) {
        try {
            if (childmenu['children'] != undefined && childmenu['children'] != null && childmenu['children'].length > 0) {
                var direction = this.direction;
                $(document).ready(function () {
                    $('.dropdown-submenu a.third-child').hover(function (e) {
                        if (direction == 'left' || direction == 'top') {
                            $(this).show();
                            if (!$(this).parent().siblings().children().has('ul').is(':visible')) {
                                $(this).parent().siblings().children('ul').hide();
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                            }
                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-right');
                            }
                        }
                        if (direction == 'right') {
                            $(this).show();
                            if (!$(this).parent().siblings().children().has('ul').is(':visible')) {
                                $(this).parent().siblings().children('ul').hide();
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                            }
                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-left');
                            }
                        }
                        $(this).next('ul').show();
                    });
                });
            } else {
                $(document).ready(function () {
                    if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                        $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                        $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                        $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    newTab(menu) {
        try {
            // let _domain = window.location.href;
            // let url = _domain.substring(0, _domain.indexOf(route));
            // if (url && url != "" && menu && menu['target']) {
            //     let link: string = '/' + menu['target'];
            //     let newTab = window.open(url + link, "_blank");
            // }
        } catch (error) {
            console.log(error);
        }
    }

    // navigationPosition() {
    //     try {
    //         if (this.separateScroll) {
    //             $(window).ready(function () {
    //                 $(".sidenav").addClass("fixedNav");
    //             });
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
}

import {
  Component, OnInit, Input, EventEmitter, Output, forwardRef, HostListener,
  OnChanges
} from '@angular/core';
import { Radio } from './radio';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
const noop = () => {
};
@Component({
  selector: 'radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true
    }
  ]
})
export class RadioButtonComponent implements OnInit, OnChanges, ControlValueAccessor {

  constructor() { }
  @Input('radio-type')
  radioType: any = "default";

  @Input('radio-group')
  radioGroup: any = "";

  @Input('font-color')
  fontColor: any;

  @Input('radio-color')
  radioColor: any;

  @Input('radio-color-hover')
  radioColorHover: any;

  @Input('radio-inner-color')
  radioInnerColor: any;

  @Input('data')
  data: any = [];

  @Input('layout')
  layout: any = "inline";

  @Output('onChange') change = new EventEmitter<any>();


  entries: Radio[];
  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    if (this.radioGroup === undefined) {
      this.radioGroup = "test" + Math.random();
    }
    this.entries = this.data;
    if (this.radioType === "classic") {
      this.classicRadio();
    } else {
      this.defaultRadio();
    }
  }


  onSelectionChange(entry) {
    try {
      let selectedEntry: any;
      selectedEntry = Object.assign({}, selectedEntry, entry);
      this.change.emit({
        value: selectedEntry
      });
    } catch (error) {
      console.log("onSelectionChange(), :" + error);
    }
  }

  getUniqueId(id: any, radioGroup: any, index: any) {
    return "" + id + "" + radioGroup + "" + index;
  }



  classicRadio() {
    try {
      let silderBackGround = '#A8AAC1';
      let sliderRoundButtonInactivColor = "#6C788A";
      let sliderRoundButtonActivColor = "#F5F3EE";
      let radioTextColor = "#7D1935";
      let css = 'input[type=radio]{display:none;}';
      let cssClass1 = '.container label {position: relative;}';
      let cssClass2 = '.section {padding: 5px;padding-left: 50px;}';
      let cssClass3 = ".container {margin-bottom: 10px;}";
      let cssClass4 = ".container span.radio:hover {cursor: pointer;}";
      let cssClass5 = ".container span::before,.container span::after {content: '';position: absolute;top: 0;bottom: 0;margin: auto;}";
      let css1 = '.container span.radio::before {left: -52px;width: 45px;height: 25px;background-color:' + silderBackGround + ';border-radius: 50px;}';
      let css2 = ' .container span.radio::after {left: -49px;width: 17px;height: 17px;border-radius: 10px;background-color: ' + sliderRoundButtonInactivColor + ';transition: left .25s, background-color .25s;}';
      let css3 = 'input[type="radio"]:checked + label span.radio::after {left: -27px;background-color: ' + sliderRoundButtonActivColor + ';}';
      let radioText = 'span.radio{font-size: 1.1em;font-weight: 250;color: ' + radioTextColor + ';}';
      let style = document.createElement('style');
      style.appendChild(document.createTextNode(css));
      style.appendChild(document.createTextNode(css1));
      style.appendChild(document.createTextNode(css2));
      style.appendChild(document.createTextNode(css3));
      style.appendChild(document.createTextNode(radioText));
      style.appendChild(document.createTextNode(cssClass1));
      style.appendChild(document.createTextNode(cssClass2));
      style.appendChild(document.createTextNode(cssClass3));
      style.appendChild(document.createTextNode(cssClass4));
      style.appendChild(document.createTextNode(cssClass5));
      document.getElementsByTagName('head')[0].appendChild(style);
    } catch (error) {
      console.log("classicRadio() , :" + error);
    }
  }

  get display(): string {
    if (this.layout === "row") {
      return "block";
    } else {
      return "inline-flex"
    }
  }

  defaultRadio() {
    try {
      let disabledCircleColor = "#7b7b7b";
      let disabledRadioCSS = ".control--radio input:disabled~.control__indicator:after {background:" + disabledCircleColor + "}";
      let radioButtonInnerCircleColor = "#fff";
      if (this.radioInnerColor != undefined) {
        radioButtonInnerCircleColor = this.radioInnerColor;
      }
      let radioButtonInnerCircleCSS = ".control--radio .control__indicator:after {top: 7px; left: 7px; width: 6px; height: 6px; border-radius: 50%; background:" + radioButtonInnerCircleColor + "}";

      let disabledTickColor = "#7b7b7b";
      let disabledTickCSS = ".control--checkbox input:disabled~.control__indicator:after {border-color:" + disabledTickColor + "}";

      let checkBoxTickBorderColor = "#fff";
      let checkBoxTickCSS = ".control--checkbox .control__indicator:after {top: 4px;left: 8px;width: 3px;height: 8px;transform: rotate(45deg);border: solid " + checkBoxTickBorderColor + ";border-width: 0 2px 2px 0;}";

      let showCheckBoxMarkCSS = ".control input:checked~.control__indicator:after {display: block;}";

      let checkMark = ".control__indicator:after {position: absolute;display: none;content: '';}";

      let disabledStateColor = "#e6e6e6";
      let disabledStateCSS = ".control input:disabled~.control__indicator {pointer-events: none;opacity: .6;background: " + disabledStateColor + ";}";

      let hoverStateWhilstCheckedColor = "#0e647d";

      if (this.radioColorHover != undefined) {
        hoverStateWhilstCheckedColor = this.radioColorHover;
      }
      let hoverStateWhilstCheckedCSS = ".control:hover input:not([disabled]):checked~.control__indicator,.control input:checked:focus~.control__indicator {background: " + hoverStateWhilstCheckedColor + ";}";

      let checkedStateColor = "#2aa1c0";
      if (this.radioColor != undefined) {
        checkedStateColor = this.radioColor;
      }
      let checkedStateCSS = ".control input:checked~.control__indicator {background: " + checkedStateColor + ";}";

      let hoverAndFocusStatesColor = "#ccc";
      let hoverAndFocusStatesCSS = ".control:hover input~.control__indicator,.control input:focus~.control__indicator {background: " + hoverAndFocusStatesColor + ";}";

      let controlRadioControlIndicatorCSS = ".control--radio .control__indicator {border-radius: 50%;}";

      let controlIndicatorColor = "#e6e6e6";
      let controlIndicatorCSS = ".control__indicator {position: absolute;top: 2px;left: 0;width: 20px;height: 20px;background: " + controlIndicatorColor + ";}";

      let controlInput = ".control input {position: absolute;z-index: -1;opacity: 0;}";

      let control = ".control {margin-right: 5px; font-weight: normal;position: relative; display :" + this.display + " ;margin-bottom: 15px;padding-left: 30px;cursor: pointer;}";

      let textColor = "#8e8b8e";
      if (this.fontColor != undefined) {
        textColor = this.fontColor;
      }
      let textCSS = ".control--radio {color: " + textColor + ";}"

      let style = document.createElement('style');
      style.appendChild(document.createTextNode(control));
      style.appendChild(document.createTextNode(controlInput));
      style.appendChild(document.createTextNode(controlIndicatorCSS));
      style.appendChild(document.createTextNode(controlRadioControlIndicatorCSS));
      style.appendChild(document.createTextNode(hoverAndFocusStatesCSS));
      style.appendChild(document.createTextNode(checkedStateCSS));
      style.appendChild(document.createTextNode(hoverStateWhilstCheckedCSS));
      style.appendChild(document.createTextNode(disabledStateCSS));
      style.appendChild(document.createTextNode(checkMark));
      style.appendChild(document.createTextNode(showCheckBoxMarkCSS));
      style.appendChild(document.createTextNode(checkBoxTickCSS));
      style.appendChild(document.createTextNode(disabledTickCSS));
      style.appendChild(document.createTextNode(radioButtonInnerCircleCSS));
      style.appendChild(document.createTextNode(disabledRadioCSS));
      style.appendChild(document.createTextNode(textCSS));
      document.getElementsByTagName('head')[0].appendChild(style);
    } catch (error) {
      console.log("defaultRadio() , :" + error);
    }
  }
  private innerValue: any = '';
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}

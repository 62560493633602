import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { domain, api_url, getParam } from '../../common';
import { ApiService } from './api.service';
import {
  passwordMaxLengthKey, passwordMinLengthKey, userIdMaxLengthKey, userIdMinLengthKey, mobileNoMaxLengthKey, mobileNoMinLengthKey, facebookidMaxLengthKey,
  facebookidMinLengthKey, firstNameMaxLengthKey, firstNameMinLengthKey, hintMinLengthKey, hintMaxLengthKey, lastNameMaxLengthKey, lastNameMinLengthKey,
  linkedinidMaxLengthKey, linkedinidMinLengthKey, skypeidMaxLengthKey, skypeidMinLengthKey, twitteridMaxLengthKey, twitteridMinLengthKey, serviceNameMaxLength,
  serviceNameMinLength, serviceAliasMaxLength, serviceAliasMinLength, serviceDisplayMaxLength, serviceDisplayMinLength, dateFormat, KEY_SYSTEM_TYPE, VALUE_SYSTEM_TYPE,
  KEY_DEFAULT_DOMAIN, fileMaxSizeKey, KEY_RESELLER_DEFAULT_DOMAIN, KEY_SYSTEM_CURRENCY_SYMBOL,
  KEY_SYSTEM_CURRENCY_CODE,
  DEFAULT_SYSTEM_CURRENCY_SYMBOL,
  DEFAULT_SYSTEM_CURRENCY_CODE,
  KEY_ALERT_FILE_UPLOAD_SIZE,
  ALLOW_SUBUSER_APIKEY,
  OLD_PASSWORD_REMEMBER_COUNT,
  GROWL_DISPLAY_TIME, DASHBOARD_NOTIFICATION, THIRDPARTY_SEE_PRICING_LINK, THIRDPARTY_SEE_PRICING_LINK_URL, PASSWORD_STRENGTH_MESSAGE, ALLOW_CAPTCHA_SERVICE
} from './parameters.key';


// const jMoment = require('moment-jdateformatparser');
import * as jMoment from 'moment-jdateformatparser';
import * as moment from 'moment';
// const moment = require('moment');
@Injectable()
export class ParametersService {
  private notification: any;
  constructor(public apiService: ApiService) {
  }

  public allowCaptchaCode='off';
  public userIdMinLength: number = 3;
  public userIdMaxLength: number = 8;
  public passwordMinLength: number = 6;
  public passwordMaxLength: number = 8;
  public minMobileNo: number = 9;
  public maxMobileNo: number = 14;
  public firstNameMaxLength: number = 20;
  public firstNameMinLength: number = 3;
  public lastNameMaxLength: number = 20;
  public lastNameMinLength: number = 3;
  public hintMaxLength: number = 20;
  public hintMinLength: number = 3;
  public twitteridMaxLength: number = 20;
  public twitteridMinLength: number = 6;
  public facebookidMaxLength: number = 25;
  public facebookidMinLength: number = 6;
  public skypeidMaxLength: number = 25;
  public skypeidMinLength: number = 6;
  public linkedinidMaxLength: number = 25;
  public linkedinidMinLength: number = 6;
  public serviceAliasMaxLength: number = 20;
  public serviceAliasMinLength: number = 3;
  public serviceNameMaxLength: number = 20;
  public serviceNameMinLength: number = 3;
  public serviceDisplayMaxLength: number = 20;
  public serviceDisplayMinLength: number = 3;
  private _dateFormat: any = "YYYY-MM-DD HH:mm";
  private _SystemType: any;
  private _defaultDomain: any;
  public fileSize: any;
  private _alertFileUploadSize: any;
  private _resellerDefaultDomain: any;
  private _systemCurrencyCode = DEFAULT_SYSTEM_CURRENCY_CODE;
  private _systemCurrencySymbol = DEFAULT_SYSTEM_CURRENCY_SYMBOL;
  private _allowsubUserApiKey: any;
  private passwordRememberCount: number;
  private growlMsgDisplayTime: number = 3000;
  private _forgetPasswordRedirectUrl: any;
  private _thirdPartySeePricingLink: boolean;
  private _thirdPartySeePricingLinkUrl: any;
  private _passwordStrengthMessage: any;
  setParameters() {
    try {
      let url = api_url + getParam;
      this.apiService.post(url, domain).subscribe(data => {
        let status = data['status'];
        if (status) {
          if (status['error-code'] && status['error-code'] == "000") {
            let parameterList = data['parameter-list'];
            if (parameterList) {
              parameterList.forEach(element => {
                this.setKey(element['key'], element['value']);
              });
            }
          }
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  getAllowCaptchCode(){
    return this.allowCaptchaCode;
  }

  setPasswordMinLength(value: any) {
    try {
      if (value) {
        this.passwordMinLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getPasswordMinLength() {
    return this.passwordMinLength;
  }

  setPasswordMaxLength(value: any) {
    try {
      if (value) {
        this.passwordMaxLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getPasswordMaxLength() {
    return this.passwordMaxLength;
  }

  setUserIdMinLength(value: any) {
    try {
      if (value) {
        this.userIdMinLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getUserIdMinLength() {
    return this.userIdMinLength;
  }
  setUserIdMaxLength(value: any) {
    try {
      if (value) {
        this.userIdMaxLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getUserIdMaxLength() {
    return this.userIdMaxLength;
  }

  setMobileNoMinLength(value: any) {
    try {
      if (value) {
        this.minMobileNo = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getMobileNoMinLength() {
    return this.minMobileNo;
  }

  setMobileNoMaxLength(value: any) {
    try {
      if (value) {
        this.maxMobileNo = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getMobileNoMaxLength() {
    return this.maxMobileNo;
  }


  setKey(key: any, value: any) {
    try {
      if (key && value) {
        switch (key) {
          case passwordMaxLengthKey: {
            this.setPasswordMaxLength(value);
            break;
          }
          case passwordMinLengthKey: {
            this.setPasswordMinLength(value);
            break;
          }
          case userIdMaxLengthKey: {
            this.setUserIdMaxLength(value);
            break;
          }
          case userIdMinLengthKey: {
            this.setUserIdMinLength(value);
            break;
          }
          case mobileNoMinLengthKey: {
            this.setMobileNoMinLength(value);
            break;
          }
          case mobileNoMaxLengthKey: {
            this.setMobileNoMaxLength(value);
            break;
          }
          case firstNameMaxLengthKey: {
            this.setFirstNameMaxLength(value);
            break;
          }
          case firstNameMinLengthKey: {
            this.setFirstNameMinLength(value);
            break;
          }
          case lastNameMinLengthKey: {
            this.setLastNameMinLength(value);
            break;
          }
          case lastNameMaxLengthKey: {
            this.setLastNameMaxLength(value);
            break;
          }
          case hintMinLengthKey: {
            this.setHintMinLength(value);
            break;
          }
          case hintMaxLengthKey: {
            this.setHintMaxLength(value);
            break;
          }
          case twitteridMaxLengthKey: {
            this.setTwitteridMaxLength(value);
            break;
          }
          case twitteridMinLengthKey: {
            this.setTwitteridMinLength(value);
            break;
          }
          case facebookidMaxLengthKey: {
            this.setFacebookidMaxLength(value);
            break;
          }
          case facebookidMinLengthKey: {
            this.setFacebookidMinLength(value);
            break;
          }
          case skypeidMaxLengthKey: {
            this.setSkypeidMaxLength(value);
            break;
          }
          case skypeidMinLengthKey: {
            this.setSkypeidMinLength(value);
            break;
          }
          case linkedinidMaxLengthKey: {
            this.setLinkedinidMaxLength(value);
            break;
          }
          case linkedinidMinLengthKey: {
            this.setLinkedinidMinLength(value);
            break;
          }
          case serviceAliasMaxLength: {
            this.setServiceAliasMaxLength(value);
            break;
          }
          case serviceAliasMinLength: {
            this.setServiceAliasMinLength(value);
            break;
          }
          case serviceNameMaxLength: {
            this.setServiceNameMaxLength(value);
            break;
          }
          case serviceNameMinLength: {
            this.setServiceNameMinLength(value);
            break;
          }
          case serviceDisplayMaxLength: {
            this.setServiceDisplayMaxLength(value);
            break;
          }
          case serviceDisplayMinLength: {
            this.setServiceDisplayMinLength(value);
            break;
          }
          case dateFormat: {
            this.setDateFormat(value);
            break;
          }
          case KEY_SYSTEM_TYPE: {
            this.systemType = value;
            break;
          }
          case KEY_DEFAULT_DOMAIN: {
            this.defaultDomain = value;
            break;
          } case fileMaxSizeKey: {
            this.setfileSize(value);
            break;
          }
          case KEY_RESELLER_DEFAULT_DOMAIN: {
            this.resellerDefaultDomain = value;
            break;
          }
          case KEY_SYSTEM_CURRENCY_CODE: {
            this.systemCurrencyCode = value;
            break;
          }

          case KEY_SYSTEM_CURRENCY_SYMBOL: {
            this.systemCurrencySymbol = value;
            break;
          }

          case KEY_ALERT_FILE_UPLOAD_SIZE: {
            this.alertUploadFileSize = value;
            break;
          }
          case ALLOW_SUBUSER_APIKEY: {
            this.allowsubUserApiKey = value;
            break;
          }
          case OLD_PASSWORD_REMEMBER_COUNT: {
            this.setPasswordRememberCount(value);
            break;
          }
          case GROWL_DISPLAY_TIME: {
            this.setGrowlDisplayTime(value);
            break;
          }
          case DASHBOARD_NOTIFICATION: {
            this.notification = value;
            break;
          }
          case "forget_password_redirect_url": {
            this._forgetPasswordRedirectUrl = value;
            break;
          }
          case THIRDPARTY_SEE_PRICING_LINK: {
            this.setThirdPartySeePricingLink(value);
            break;
          }
          case THIRDPARTY_SEE_PRICING_LINK_URL: {
            this._thirdPartySeePricingLinkUrl = value;
            break;
          }
          case PASSWORD_STRENGTH_MESSAGE: {
            this._passwordStrengthMessage = value;
            break;
          }

          case ALLOW_CAPTCHA_SERVICE:{
            this.allowCaptchaCode = value;
            break;
          } 

          default: {
            break;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  setDateFormat(value) {
    try {
      if (value) {
        this._dateFormat = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getDateFormat() {
    return this._dateFormat;
  }

  get dateFormat() {
    return this._dateFormat;
  }
  setFirstNameMaxLength(value) {
    try {
      if (value) {
        this.firstNameMaxLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getFirstNameMaxLength() {
    return this.firstNameMaxLength;
  }
  setFirstNameMinLength(value) {
    try {
      if (value) {
        this.firstNameMinLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getFirstNameMinLength() {
    return this.firstNameMinLength;
  }
  setLastNameMaxLength(value) {
    try {
      if (value) {
        this.lastNameMaxLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getLastNameMaxLength() {
    return this.lastNameMaxLength;
  }
  setLastNameMinLength(value) {
    try {
      if (value) {
        this.lastNameMinLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getLastNameMinLength() {
    return this.lastNameMinLength;
  }
  setHintMaxLength(value) {
    try {
      if (value) {
        this.hintMaxLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getHintMaxLength() {
    return this.hintMaxLength;
  }
  setHintMinLength(value) {
    try {
      if (value) {
        this.hintMinLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getHintMinLength() {
    return this.hintMinLength;
  }
  setTwitteridMaxLength(value) {
    try {
      if (value) {
        this.twitteridMaxLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getTwitteridMaxLength() {
    return this.twitteridMaxLength;
  }
  setTwitteridMinLength(value) {
    try {
      if (value) { this.twitteridMinLength = value; }
    } catch (error) {
      console.log(error);
    }
  }
  getTwitteridMinLength() {
    return this.twitteridMinLength;
  }
  setFacebookidMaxLength(value) {
    try {
      if (value) {
        this.facebookidMaxLength = value;
      }
    } catch (error) {
      console.log(error);
    }
  }
  getFacebookidMaxLength() {
    return this.facebookidMaxLength;
  }
  setFacebookidMinLength(value) {
    try {
      if (value) {
        this.facebookidMinLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getFacebookidMinLength() {
    return this.facebookidMinLength;
  }
  setSkypeidMaxLength(value) {
    try {
      if (value) {
        this.skypeidMaxLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getSkypeidMaxLength() {
    return this.skypeidMaxLength;
  }
  setSkypeidMinLength(value) {
    try {
      if (value) {
        this.skypeidMinLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getSkypeidMinLength() {
    return this.skypeidMinLength;
  }
  setLinkedinidMaxLength(value) {
    try {
      if (value) {
        this.linkedinidMaxLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getLinkedinidMaxLength() {
    return this.linkedinidMaxLength;
  }
  setLinkedinidMinLength(value) {
    try {
      if (value) {
        this.linkedinidMinLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getLinkedinidMinLength() {
    return this.linkedinidMinLength;
  }

  setServiceAliasMaxLength(value) {
    try {
      if (value) {
        this.serviceAliasMaxLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getServiceAliasMaxLength() {
    return this.serviceAliasMaxLength;
  }
  setServiceAliasMinLength(value) {
    try {
      if (value) {
        this.serviceAliasMinLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getServiceAliasMinLength() {
    return this.serviceNameMinLength;
  }
  setServiceNameMaxLength(value) {
    try {
      if (value) {
        this.serviceNameMaxLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getServiceNameMaxLength() {
    return this.serviceNameMaxLength;
  }
  setServiceNameMinLength(value) {
    try {
      if (value) {
        this.serviceNameMinLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getServiceNameMinLength() {
    return this.serviceNameMinLength;
  }
  setServiceDisplayMaxLength(value) {
    try {
      if (value) {
        this.serviceDisplayMaxLength = value;
      }
    } catch (error) { console.log(error); }
  }
  getServiceDisplayMaxLength() {
    return this.serviceDisplayMaxLength;
  }
  getServiceDisplayMinLength() {
    return this.serviceDisplayMinLength;
  }
  setServiceDisplayMinLength(value) {
    try {
      if (value) {
        this.serviceDisplayMinLength = value;
      }
    } catch (error) { console.log(error); }
  }
  get momentDateFormat() {
    return jMoment().toMomentFormatString(this.dateFormat);
  }

  set systemType(value) {
    try {
      if (value) {
        this._SystemType = value;
      }
    } catch (error) { console.log(error); }
  }

  get systemType() {
    try {
      return this._SystemType ? this._SystemType : VALUE_SYSTEM_TYPE;
    } catch (error) { console.log(error); }
  }

  getDateForRequest(momentDate: any) {
    // let systemDate = moment(date, this.momentDateFormat) ? moment(date, this.momentDateFormat) : moment();
    return jMoment(momentDate).formatWithJDF(this.momentDateFormat);
  }

  set defaultDomain(value) {
    try {
      if (value) {
        this._defaultDomain = value;
      }
    } catch (error) { console.log(error); }
  }

  get defaultDomain() {
    try {
      return this._defaultDomain ? this._defaultDomain : "";
    } catch (error) { console.log(error); }
  }

  setfileSize(value) {
    try {
      if (value) {
        this.fileSize = value;
      }
    } catch (error) { console.log(error); }
  }

  getFileSize() {
    return this.fileSize;
  }

  userDefinedFormatedDate(dateObj, momentFormate) {
    try {
      if (dateObj) {
        if (moment(dateObj).isValid()) {
          return moment(dateObj).format(momentFormate);
        }
      } else {
        return "";
      }
    } catch (error) {
      console.log(error);
    }
    return "";
  }

  dateFormater(dateObjString, dateActualFormat, expectedFormat) {
    try {
      if (dateObjString) {
        if (moment(dateObjString, dateActualFormat).isValid()) {
          return moment(dateObjString, dateActualFormat).format(expectedFormat);
        }
      } else {
        return "";
      }
    } catch (error) {
      console.log(error);
    }
    return "";
  }

  get hhmmssFormate() {
    return "HH:mm:ss";
  }

  get yearRangeForCalender() {
    return "1950:2050";
  }
  get momentDateFormatWithoutTime() {
    let dateFormat: string = this.momentDateFormat;
    if (dateFormat) {
      if (dateFormat.indexOf("HH") != -1) {
        dateFormat = dateFormat.slice(0, dateFormat.indexOf("HH"));
      } else if (dateFormat.indexOf("hh") != -1) {
        dateFormat = dateFormat.slice(0, dateFormat.indexOf("hh"));
      }
    }
    return dateFormat.trim();
  }

  set resellerDefaultDomain(value) {
    try {
      if (value) {
        this._resellerDefaultDomain = value;
      }
    } catch (error) { console.log(error); }
  }

  get resellerDefaultDomain() {
    try {
      return this._resellerDefaultDomain ? this._resellerDefaultDomain : "";
    } catch (error) { console.log(error); }
  }

  get systemCurrencyCode() {
    return this._systemCurrencyCode;
  }

  set systemCurrencyCode(value) {
    this._systemCurrencyCode = value;
  }

  get systemCurrencySymbol() {
    return this._systemCurrencySymbol;
  }

  set systemCurrencySymbol(value) {
    this._systemCurrencySymbol = value;
  }


  set alertUploadFileSize(value) {
    try {
      if (value) {
        this._alertFileUploadSize = value;
      }
    } catch (error) { console.log(error); }
  }

  get alertUploadFileSize() {
    return this._alertFileUploadSize;
  }

  getAlertUploadFileSize() {
    return this.alertUploadFileSize;
  }

  set allowsubUserApiKey(value) {
    try {
      if (value) {
        this._allowsubUserApiKey = value;
      }
    } catch (error) { console.log(error); }
  }

  get allowsubUserApiKey() {
    try {
      return this._allowsubUserApiKey ? this._allowsubUserApiKey : "";
    } catch (error) { console.log(error); }
  }
  getAllowSubUserApiKey() {
    return this.allowsubUserApiKey;
  }
  setPasswordRememberCount(value: any) {
    try {
      if (value) {
        this.passwordRememberCount = value;
      }
    } catch (error) {
      console.log(error);
    }
  }

  getPasswordRememberCount() {
    return this.passwordRememberCount;
  }

  setGrowlDisplayTime(value: any) {
    try {
      if (value) {
        this.growlMsgDisplayTime = value;
      }
    } catch (error) {
      console.log(error);
    }
  }

  getGrowlDisplayTime() {
    return this.growlMsgDisplayTime;
  }

  get dashboardMessage() {
    return this.notification;
  }

  get forgetPasswordRedirectUrl() {
    return this._forgetPasswordRedirectUrl;
  }

  setThirdPartySeePricingLink(value: any) {
    try {
      if (value) {
        this._thirdPartySeePricingLink = value == "on" ? true : false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  get thirdPartySeePricingLink() {
    return this._thirdPartySeePricingLink;
  }

  get thirdPartySeePricingLinkUrl() {
    return this._thirdPartySeePricingLinkUrl;
  }
  get passwordStrengthMessage() {
    return this._passwordStrengthMessage ? this._passwordStrengthMessage : "Kindly set strong password";
  }
}

import { Component, OnInit } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';


import { TranslateService } from 'ng2-translate/ng2-translate';
import { Theme } from '../../src/app/shared/service/guiTheme';
import { GUIService } from '../../src/app/shared/service';
import { routerTransition } from './router.animation';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  // selectedlang = "";
  constructor(public translate: TranslateService, public router: Router, public guiService: GUIService) {
    this.guiService.startProgress();
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    });
  }
  ngOnInit() {
    this.guiService.startProgress();
  }

  ngAfterViewInit() {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.guiService.stopProgress();
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.guiService.startProgress();
  }


  navigationInterceptor(event: RouterEvent): void {
    try {
      if (event instanceof NavigationStart) {
        this.guiService.startProgress();

      }
      if (event instanceof NavigationEnd) {
        this.guiService.stopProgress();
      }

      if (event instanceof NavigationCancel) {
        this.guiService.stopProgress();
      }
      if (event instanceof NavigationError) {
        this.guiService.stopProgress();
      }
    } catch (error) {
      console.log(error);
    }
  }

  get theme(): Theme {
    return this.guiService.getGUITheme() ? this.guiService.getGUITheme() : new Theme();
  }


}


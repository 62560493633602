import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/service/api.service';
import { KEY_FOR_USER_DETAIL_STORAGE } from 'src/app/config';

@Injectable()
export class UserService {
  userACLList: Array<any> = [];
  private userKey = KEY_FOR_USER_DETAIL_STORAGE + 'user-details';
  constructor(private apiService: ApiService) { }

  setUser(userDetails: any) {
    sessionStorage.setItem(this.userKey, JSON.stringify(userDetails));
  }

  removeUser() {
    sessionStorage.removeItem(this.userKey);
  }

  isValidUser() {
    if (sessionStorage.getItem(this.userKey) && this.getId()) {
      if (this.apiService.checkValidSessionToken()) {
        return true;
      }
    }
    return false;
  }

  getId() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['id'];
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }
  getUserCurrency() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['currency'];
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }
  getUserId() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['user-id'];
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }
  getUsersDomain() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['domain'];
      }
    } catch (error) {
      console.log(error);
    }
  }

  getUserType() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['user-type'];
      }
    } catch (error) {
      console.log(error);
    }
  }

  getParentId() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['parent-user-id'];
      }
    } catch (error) {
      console.log(error);
    }
  }

  getSubuserId() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['subuser-id'];
      }
    } catch (error) {
      console.log(error);
    }
  }

  getSubuserUserId() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['subuser-user-id'];
      }
    } catch (error) {
      console.log(error);
    }
  }
  getUserCurrentStatus() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['user-status'];
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }
  setUserACLList(userAclList: any) {
    this.userACLList = userAclList;
  }
  getUserAclRights() {
    return this.userACLList;
  }
  isRightsAvailable(name: string) {
    try {
      let flag = false;
      this.userACLList.filter(element => { return (name == element['name']) })
        .forEach(data => { flag = true })
      return flag;
    } catch (error) {
      console.log(error);
    }
  }

  getUserPlanId() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['plan-id'];
      }
    } catch (error) {
      console.log(error);
    }
    return '';
  }

  getUserCurrencyCode() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['currency-code'];
      }
    } catch (error) {
      console.log(error);
    }
    return '';
  }

  getUserPlanName() {
    try {
      if (sessionStorage.getItem(this.userKey)) {
        const user = JSON.parse(sessionStorage.getItem(this.userKey));
        return user['user-plan'];
      }
    } catch (error) {
      console.log(error);
    }
    return '';
  }
}

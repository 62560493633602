import { Directive, OnInit } from '@angular/core';

// @Component({
//   selector: 'card-content',
//   templateUrl: './card-content.component.html',
//   styleUrls: ['./card-content.component.css']
// })
@Directive({
  selector: 'card-content',
  host: {
    '[class.mat-card-content]': 'true'
  }
})
export class CardContent { }

import { apiPath, JWT_KEY } from 'src/app/config';
export const JWT_KEY_FOR_SYSTEM = JWT_KEY;
export const api_url = apiPath;
export const domain = 'sms.vas.co.zw';//  '138.207.67.155:8901'; // 'aggregator.teleoss.co.in'; "reseller.teleoss.co.in";//
export const apiLogin = 'login';
export const apiLogout = 'logout';
export const landingPages = 'landing';
export const getParam = 'parameter/lookup';
export const getNavigation = 'navigation';
export const apiViewProfile = 'profile/lookup';
export const apiUpdateProfile = 'profile/update';
export const dateFormat = 'dd-mm-yy'; // yy represent yyyy for all calender date time formate view in gui
export const startingYearRange = '1950';
export const apiViewUsers = 'user/lookup';
export const apiGetAllSecurityQuestions = 'securityquestion/getAll';
export const apiAssignSecurityQuestions = 'securityquestion/insert';
export const apiVerifySecurityQuestion = 'securityquestion/verify';
export const apiCreateUser = 'user/create';
export const apiEditUser = 'user/update';
export const apiViewPlan = 'offerplan/list';
export const apiUpdatePlan = 'offerplan/update';
export const apiViewAllRatePlan = 'ratecard/list';
export const apiCreatePlan = 'offerplan/create';
export const apiEditPlan = 'offerplan/update';
export const apiForgetPassword = 'forgetpassword';
export const apiUpdateRate = 'ratecard/update';
export const apiDeleteRate = 'ratecard/remove';
export const apiAddRate = 'ratecard/create';
export const apiGetAllUnit = 'currency/list';
export const apiUpdateUnit = 'currency/update';
export const apiAddUnit = 'currency/create';
export const apiDeleteUnit = 'currency/remove';
export const getACL = 'acl/get';
export const apiViewACLGroups = 'aclgroups/get';
export const apiChangePassword = 'changepassword';
export const apiAssignACLGroups = 'aclgroups/assign';
export const apiAssignACLUsers = 'aclusers/assign';
export const apiInsertACLGroups = 'aclgroups/create';
export const apiRemoveACLGroups = 'aclgroups/remove';
export const apiAddPages = 'theme/page/add';
export const apiEditPages = 'theme/page/edit';
export const apiDeletePages = 'theme/page/delete';
export const apiSetTheme = 'theme/settheme';
export const apiAddImages = 'theme/image/add';
export const apiDeleteImages = 'theme/image/delete';
export const apiUserRecharge = 'recharge';
export const apiRechargeInfomation = 'rechargerinformation/lookup';
export const apiUploadFile = 'upload/file';
export const apiUserRechargeReport = 'recharge/lookup';
export const getAllRemimders = 'notification/config/lookup';
export const updateRemimders = 'notification/config/update';
export const getGrowlNotification = 'notification/growl/lookup';
export const updateNotification = 'notification/growl/update';
export const apiResetPassword = 'resetpassword';
export const apiGetTheme = 'theme/gettheme';
export const getLanguages = 'language/get';
export const setLanguagePath = 'language/set';
export const scheduleRecharge = 'recharge/schedulerecharge/activate';
export const getApiKey = 'apikey/lookup';
export const deleteApiKey = 'apikey/delete';
export const addApiKey = 'apikey/insert';
export const generateApiKey = 'apikey/generateapikey';
export const apiCompanyGroupList = 'companygroup/lookup';
export const apiCompanyGroupAdd = 'companygroup/add';
export const apiCompanyGroupUpdate = 'companygroup/update';
export const apiCompanyGroupDelete = 'companygroup/delete';
export const apiBalanceTransfer = 'companygroup/balance/transfer';
export const apiAccountSalesOwnerLookup = 'changeowner/lookup';
export const apiAccountSalesOwnerUpdate = 'changeowner/update';
export const apiUserOverrideRateLookup = 'userrate/lookup';
export const apiUserOverrideRateUpdate = 'userrate/update';
export const getConditionsList = 'firewall/condition/lookup';
export const getFilterTypeList = 'firewall/type/lookup';
export const getFirewallTemplatesList = 'firewall/lookup';
export const addFirewallTemplatesList = 'firewall/add';
export const updateFirewallTemplatesList = 'firewall/update';
export const deleteFirewallTemplates = 'firewall/delete';
export const apiCreateSubUser = 'subuser/create';
export const getAlertList = 'alert/lookup';
export const addAlertList = 'alert/add';
export const updateAlertList = 'alert/update';
export const deleteAlertList = 'alert/delete';
export const addReminders = 'notification/config/add';
export const getAlertOfUser = 'alert/user';
export const apiGetUserReport = 'userreport/lookup';
export const apiGetSystemParameterLookup = 'parameter/getall';
export const apiGetSystemParameterUpdate = 'parameter/update';
export const apiGetAllCurrencyCode = 'currency/code/list';
export const apiVerifyPassword = 'verify/password';
export const apiActivateUser = 'activate/user';
export const apiGetNotificationReport = 'notification/report';
export const apiResendNotification = 'notification/resend';
export const apiSubuserCreate = 'subuser/create';
export const excelPluginDownload = 'excels/create';
export const apiForgoPassword = "19aPQ121985to1XY014/forgotpassword";
export const apiLoginForgotPasssword = '19aPQ121985to1XY014/resetPassword';
export const apiSignUp = "signup/insert";
export const apiShowSignUpFlag = "signup/list"
export const apiSignUpUpdate = "signup/update"
export const apiUserPaymentLookup = "payment/user/lookup";
export const apiPaymentLookup = "payment/lookup";
export const apiUpdateUserPayment = "payment/user/update";
export const API_CONVERT_CURRENCY = "finance/currency/convert";
export const apiInsertUserPayment = "payment/user/insert";
export const apiGetIsValidPlan = 'offerplan/getplan';

import { Directive, ElementRef, HostListener, Input, Renderer2, HostBinding, OnInit } from '@angular/core';

@Directive({
    selector: '[dRequired]',
})
export class RequiredValidator implements OnInit {
    @HostBinding('class.d-required') inputRequired = true;
    constructor(private element: ElementRef) {
    }

    ngOnInit() {
        this.setCSS();
    }

    setCSS() {
        let requiredClass = ".d-required:after{ content:'*'; color:#ff0000;}"
        let style = document.createElement('style');
        style.appendChild(document.createTextNode(requiredClass));
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    @HostListener('keyup', ['$event']) onKeyup(event) { this.isBlank(event); }
    @HostListener('keydown', ['$event']) onKeyDown(event) { this.isBlank(event); }

    isBlank(event) {
        if (event.value) {
            console.log(event.value);
        }
    }
}
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'msg-center',
  styleUrls: ['./msgcenter.css'],
  templateUrl: './msgcenter.html'
})
export class Msgcenter {
  @Input() public notificationList: Array<any>;
  @Input() public textColor: any;
  @Input() public headingColor: any;
  @Input() public iconColor: any;
  // tslint:disable-next-line:no-output-rename
  @Output('onMsgClick') change = new EventEmitter<any>();

  constructor() {
  }
  get color() {
    return this.textColor;
  }
  get hColor() {
    return this.headingColor;
  }
  get iColor() {
    return this.iconColor;
  }
  get notifications() {
    return this.notificationList;
  }
  get count() {
    if (this.notificationList) {
      return this.notificationList.length;
    } else {
      return 0;
    }
  }
  check(msg: any) {
    try {
      if (msg) {
        this.onSelectionChange(msg);
      }
    } catch (error) {
      console.log(error);
    }
  }

  onSelectionChange(msg) {
    try {
      if (msg) {
        let selectedEntry: any;
        selectedEntry = Object.assign({}, selectedEntry, msg);
        this.change.emit({
          value: selectedEntry
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
    setColor(tColor) {
        try {
            return {
                'color': tColor,
            };
        } catch (error) {
            console.log(error);
        }
    }

    setTheme(tColor) {
        try {
            return {
                'background-color': tColor,
            };
        } catch (error) {
            console.log(error);
        }
    }
}

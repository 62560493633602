import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ApiService,
  GUIService,
  ParametersService,
  UserService
} from './service';
export function paramServiceFactory(param: ParametersService): Function {
  return () => {
    return param.setParameters();
  }; // => required, otherwise `this` won't work inside StartupService::load
}
export function themeServiceFactory(gui: GUIService): Function {
  return () => {
    return gui.setGUI();
  }; // => required, otherwise `this` won't work inside StartupService::load
}
export function languageServiceFactory(gui: GUIService): Function {
  return () => {
    return gui.setLanguage();
  }; // => required, otherwise `this` won't work inside StartupService::load
}
@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    ApiService,
    GUIService,
    ParametersService,
    {
      provide: APP_INITIALIZER,
      useFactory: paramServiceFactory,
      deps: [ParametersService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: themeServiceFactory,
      deps: [GUIService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: languageServiceFactory,
      deps: [GUIService],
      multi: true
    },
    UserService
  ]
})
export class SharedServiceModule {}

import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
declare var d3;
@Component({
  selector: 'graph-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.css']
})
export class StickerComponent implements OnInit {
  public _graphValue: number = 0;
  @Input() public graphColor: String[] = ["white", "transparent"];
  @Input() public graphKey: String[] = ["Success", "Pending"];
  @Input() public header: String;
  @Input() public textColor: String = "white";
  @Input() public label: String;
  @Input() public showNumber: boolean = false;
  @Input() public displayNumber: Number;
  donutChart: any = {};
  data: any = [];
  constructor() { }
  @ViewChild("stickerText") stickertext: any;
  @ViewChild("sticker") sticker: any;
  ngOnInit() {
    this.setSticker();
  }
  setSticker() {
    try {
      this.setChart();
      this.stickertext.nativeElement.style.color = this.textColor;
      if (this.showNumber) {
        if (this.displayNumber > 20) {
          this.sticker.nativeElement.style.backgroundColor = "seagreen";
        } else if (this.displayNumber <= 20 && this.displayNumber > 0) {
          this.sticker.nativeElement.style.backgroundColor = "#E6E679";
          this.stickertext.nativeElement.style.color="red";
        } else {
          this.sticker.nativeElement.style.backgroundColor = "#C63D0F";
        }
        this.setData();
      } else {
        if (this.graphValue <= 100) {
          if (this.graphValue > 20) {
            this.sticker.nativeElement.style.backgroundColor = "seagreen";
          } else if (this.graphValue <= 20 && this.graphValue > 0) {
            this.sticker.nativeElement.style.backgroundColor = "#E6E679";
            this.stickertext.nativeElement.style.color="red";
          } else {
            this.sticker.nativeElement.style.backgroundColor = "#C63D0F";
          }
          this.setData();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  get graphValue() {
    return this._graphValue;
  }

  @Input()
  set graphValue(value: number) {
    this._graphValue = value;
    this.setSticker();
  }
  setData() {
    try {
      this.data = [];
      let tempDataSuccess = {
        key: this.graphKey[0],
        y: this.graphValue,
        y1:this.displayNumber
      }
      this.data.push(tempDataSuccess);
      let tempDatapending = {
        key: this.graphKey[1],
        y: 100 - this.graphValue,//this.graphValue
        y1:""
      }
      this.data.push(tempDatapending);
    } catch (error) {
      console.log(error);
    }
  }
  // graphCenterTitle: any;
  // setGraphTitle() {
  //   if (this.showNumber) {
  //     this.graphCenterTitle = this.displayNumber + "days";
  //   } else {
  //     this.graphCenterTitle = d3.format(',.1f')(this.graphValue) + "%";
  //   }
  // }
  setChart() {
    try {
      if (this.showNumber) {
        this.donutChart = {
          chart: {
            title: this.displayNumber + " d",
            type: 'pieChart',
            color: this.graphColor,
            height: 80,
            width: 80,
            donut: true,
            x: function (d) { return d.key; },
            y: function (d) { return d.y; },
            showLabels: false,
            duration: 100,
            showLegend: false,
            noData: "",
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
            tooltip: {
              contentGenerator: function(d) {return  '<p>'+d.data.key+': '+ d.data.y1+'</p>'; }
            }
          }
        }
      } else {
        this.donutChart = {
          chart: {
            title: d3.format(',.1f')(this.graphValue) + "%",
            type: 'pieChart',
            color: this.graphColor,
            height: 80,
            width: 80,
            donut: true,
            x: function (d) { return d.key; },
            y: function (d) { return d.y; },
            showLabels: false,
            duration: 100,
            showLegend: false,
            noData: "",
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
            tooltip: {
              contentGenerator: function(d) {return  '<p>'+d.data.key+': '+ d3.format(',.1f')(d.data.y)+'%</p>'; }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from 'ng2-translate/ng2-translate';

import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { NavigationBarComponent } from './layout/navigation-bar/navigation-bar.component';
import { TopComponent } from './layout/top/top.component';
import { GrowlModule } from 'primeng/primeng';
import { Http } from '@angular/http';
import { CardModule, InputModule } from 'src/app/Component/component';
import { RequiredValidator } from './directive/required.directive';
import { SearchFilterPipe } from './pipe/search-filter.pipe';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { ProgressBarModule } from 'src/app/Component/component';
import { GraphModule } from 'src/app/Component/component';
import { StickerComponent } from './component/sticker/sticker.component';
import { Msgcenter } from './component/msgcenter/msgcenter.component';
import { NavigationCollapsibleComponent } from '../shared/layout/navigation-collapsible/navigation-collapsible.component';
import { PreviewNavigationBarComponent } from '../shared/layout/preview-navigation-bar/preview-navigation-bar.component';

// tslint:disable-next-line:max-line-length
import { PreviewNavigationCollapsibleComponent } from '../shared/layout/preview-navigation-collapsible/preview-navigation-collapsible.component';
import {OrderPipe} from './pipe/sort/order.pipe';
@NgModule({
  imports: [
    CommonModule,
    GrowlModule,
    ProgressBarModule,
    TranslateModule,
    GraphModule
  ],
  exports: [FooterComponent, HeaderComponent, NavigationBarComponent, TopComponent,
     RequiredValidator, SearchFilterPipe, StickerComponent, Msgcenter,
      NavigationCollapsibleComponent, PreviewNavigationBarComponent, PreviewNavigationCollapsibleComponent, OrderPipe],
  declarations: [FooterComponent, HeaderComponent, NavigationBarComponent,
     TopComponent, RequiredValidator, SearchFilterPipe, NotFoundComponent, StickerComponent,
      Msgcenter, NavigationCollapsibleComponent, PreviewNavigationBarComponent, PreviewNavigationCollapsibleComponent, OrderPipe],
  providers: [],
})
export class SharedModule { }

import { Component, OnInit, Input } from '@angular/core';
import { menubar } from './../navigation-bar/menubar';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

@Component({
    selector: 'preview-navi-collapsible',
    templateUrl: './preview-navigation-collapsible.component.html',
    styleUrls: ['./preview-navigation-collapsible.component.css']
})
export class PreviewNavigationCollapsibleComponent implements OnInit {

    @Input() public menuList: menubar[];
    @Input() public iconColor: String = "#f47f41";
    @Input() public direction: String;
    @Input() public textColor: String = "#f47f41";
    @Input() public separateScroll: boolean;
    @Input() public navigationBackgroundColor: string = "#ffffff";

    constructor(private router: Router, private activeRouter: ActivatedRoute) {
    }

    ngOnChanges() {
        setTimeout(() => this.defaultDisplay(), 50);
    }

    ngOnInit() {
        try {
            if (window.screen.width < 800) {
                this.mobileView = true;
            } else {
                this.mobileView = false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    subchildParentID: string;
    childParentID: string;

    showSubMenu(id) {
        try {
            if (this.childParentID == id) {
                this.childParentID = "-1";

            } else {
                this.childParentID = id;
            }
            this.subchildParentID = "-1";
        } catch (error) {
            console.log(error);
        }
    }

    showSubChildMenu(id) {
        try {
            if (this.subchildParentID == id) {
                this.subchildParentID = "-1";
            }
            else {
                this.subchildParentID = id;
            }
        } catch (error) {
            console.log(error);
        }
    }

    navigatorResponse(menu) {
        try {
            if (!this.navFlag) {
                this.toggle();
            }
            if (menu['children'] != undefined && menu['children'] != null && menu['children'].length > 0) {
                this.showSubMenu(menu['id']);
            }
            else {
                this.childParentID = "-2";
                this.subchildParentID = "-3";
            }
        } catch (error) {
            console.log(error);
        }
    }
    childNavigatorResponse(childmenu) {
        try {
            if (childmenu['children'] != undefined && childmenu['children'] != null && childmenu['children'].length > 0) {
                this.showSubChildMenu(childmenu['id'])
            }
        } catch (error) {
            console.log(error);
        }
    }
    childNavigatorTop(childmenu) {
        try {
            if (childmenu['children'] != undefined && childmenu['children'] != null && childmenu['children'].length > 0) { }
         
        } catch (error) {
            console.log(error);
        }
    }
    navigatorTop(menu) {
        try {
            if (menu['children'] != undefined && menu['children'] != null && menu['children'].length > 0) { }
            
        } catch (error) {
            console.log(error);
        }
    }   

    setColor(tColor) {
        try {
            return {
                'color': tColor,
            };
        } catch (error) {
            console.log(error);
        }
    }


    /////////////////////////////////////////////////

    closeAll() {
        try {
            $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
            $('.dropdown-submenu a.third-child').parent().children('ul').hide();

            if ($('.dropdown-submenu').parent().parent().hasClass('open')) {
                $('.dropdown-submenu').parent().parent().removeClass('open');
                $('.dropdown-submenu').parent().parent().children('a').children('span.caret').removeClass('caret-right');
                $('.dropdown-submenu').parent().parent().children('a').children('span.caret').removeClass('caret-left');
            }

            if ($('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').hasClass('caret-right'))
                $('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').removeClass('caret-right');

            if ($('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').hasClass('caret-left'))
                $('.dropdown a.dropdown-toggle').parent().children('a').children('span.caret').removeClass('caret-left');
        } catch (error) {
            console.log(error);
        }
    }


    checkChild(menu) {
        try {
            if (menu['children'] != undefined && menu['children'] != null && menu['children'].length > 0) {
                $(document).ready(function () {
                    $('.dropdown a.dropdown-toggle').hover(function (e) {
                        if (!$(this).parent().has('ul').hasClass('open')) {
                            $(this).parent().has('ul').addClass('open');
                            $(this).parent().siblings().has('ul').removeClass('open');
                        }
                        if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                            $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                            $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                        }
                    });
                });
            } else {
                $('.dropdown a.dropdown-toggle').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                $('.dropdown a.dropdown-toggle').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                    $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                    $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                    $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    checkSubChild(childmenu) {
        try {
            if (childmenu['children'] != undefined && childmenu['children'] != null && childmenu['children'].length > 0) {
                var direction = this.direction;
                $(document).ready(function () {
                    $('.dropdown-submenu a.third-child').hover(function (e) {
                        if (direction == 'top') {
                            $(this).show();
                            if (!$(this).parent().siblings().children().has('ul').is(':visible')) {
                                $(this).parent().siblings().children('ul').hide();
                                $(this).parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                            }
                            if ($(this).children().hasClass('caret')) {
                                $(this).children('span.caret').addClass('caret-right');
                            }
                        }
                        $(this).next('ul').show();
                    });
                });
            } else {
                $(document).ready(function () {
                    if (!$('.dropdown-submenu a.third-child').parent().siblings().children().has('ul').is(':visible')) {
                        $('.dropdown-submenu a.third-child').parent().siblings().children('ul').hide();
                        $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-right');
                        $('.dropdown-submenu a.third-child').parent().siblings().children('a').children('span.caret').removeClass('caret-left');
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }


    defaultDisplay() {
        try {
            if (this.direction != undefined) {
                if (this.direction != 'top') {
                    let i = 0;
                    let list = document.getElementById("previewMySidenav").getElementsByTagName('ul');
                    for (i = 0; i < list.length; i++) {
                        list[i].style.backgroundColor = this.navigationBackgroundColor;
                    }
                } else if (this.direction == 'top') {
                    let i = 0;
                    let list = document.getElementById("previewMyNavbar").getElementsByTagName('ul');
                    for (i = 0; i < list.length; i++) {
                        list[i].style.backgroundColor = this.navigationBackgroundColor;
                    }
                }

                if (!this.mobileView) {
                    $('.sidenav-icon').css('display', 'inline-block');
                    if (this.direction == 'left') {
                        document.getElementById("preview-main-content").style.marginLeft = "150px";
                    }
                    else if (this.direction == 'right') {
                        document.getElementById("preview-main-content").style.marginRight = "150px";
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    navFlag: boolean = true;
    toggle() {
        try {
            if (this.navFlag) {
                this.closeNav();
            } else {
                this.openNav();
            }
        } catch (error) {
            console.log(error);
        }
    }

    openNav() {
        try {
            this.navFlag = true;
            if (!this.mobileView) {
                if (this.direction == 'left') {
                    document.getElementById("preview-main-content").style.marginLeft = "150px";
                }
                else if (this.direction == 'right') {
                    document.getElementById("preview-main-content").style.marginRight = "150px";
                }
                $('.preview-sidenav-text').css('display', 'inline-block');
            }
            else {
                document.getElementById("previewMySidenav").style.display = "block";
            }
        } catch (error) {
            console.log(error);
        }
    }
    textView: boolean = true;
    mobileView: boolean = true;
    closeNav() {
        try {
            this.navFlag = false;
            this.childParentID = "-2";
            this.subchildParentID = "-3";
            if (!this.mobileView) {
                if (this.direction == 'left') {
                    document.getElementById("preview-main-content").style.marginLeft = "30px";
                } else {
                    document.getElementById("preview-main-content").style.marginRight = "30px";
                }
                $('.preview-sidenav-text').css('display', 'none');
            } else {
                document.getElementById("previewMySidenav").style.display = "none";
            }
        } catch (error) {
            console.log(error);
        }
    }
}

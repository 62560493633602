import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  tempItems: any[];

  transform(items: any[], field: any, value: any): any[] {
    if (!items) return items;
    if (!field) return items;
    if (!value) return items;
    this.tempItems = [];
    this.tempItems = items.filter(element => element[field].toLowerCase().includes(value.toLowerCase()));
    return this.tempItems;
  }

}

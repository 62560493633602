export class SMSParameterCriteria {
    key?:string[];
    value?:string;
    component?:string;
    status?:string;
    action?:string;

    constructor(key:string[],action:string){
      this.key=key;
      this.action=action;
    }
}

export const SENDER_ID_MAX_LENGTH_KEY = "senderidmaxlength";
export const SENDER_ID_MIN_LENGTH_KEY = "senderidminlength";
export const DLT_ENTITY_ID_MIN_LENGTH ="dltentityidminlength";
export const DLT_ENTITY_ID_MAX_LENGTH="dltentityidmaxlength";

export const MOBILENO_MAX_LENGTH_KEY = "mobilenomaxlength";
export const MOBILENO_MIN_LENGTH_KEY = "mobilenominlength";
export const ALLOW_OPEN_SENDERID = "open_senderid";
export const MAX_DAYS_REPORT_LIMIT = "max_days_report_limit";
export const ALLOW_CUSTOM_OPTOUT = "allow_custom_optout";
export const CUSTOM_OPTOUT_CONTENT = "custom_optout_content";
export const DLT_TEMPLATE_VALIDATION="dlt_template_validation";
export const DLT_MODULE="dlt_module";
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { RadioButtonComponent } from './radio-button.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [RadioButtonComponent],
  exports: [RadioButtonComponent],
  schemas: [NO_ERRORS_SCHEMA]

})
export class RadioModule { }
